

import Chart from 'chart.js';

 



import {
    DxDataGrid,
    DxColumn,
    DxEditing,
    DxPaging,
    DxSearchPanel,
    DxFilterRow,
    DxButton,

} from 'devextreme-vue/data-grid';
import { DxToast } from 'devextreme-vue/toast';
import Reporteservice from '../services/reportes-service';
import Periodoservice from '../services/periodo-service';
 
import DxSelectBox from 'devextreme-vue/select-box';
import notify from 'devextreme/ui/notify';
import { jsPDF } from "jspdf";
import 'jspdf-autotable';

 
 

import Cookies from "js-cookie";
window.Cookies = Cookies;

export default {
    components: {
        DxDataGrid,
        DxColumn,
        DxEditing,
        DxPaging,
        DxToast,
        DxSearchPanel,
        DxFilterRow,
       
        DxButton,
        DxSelectBox,
       
    },
    data() {
        return {
            ordentrabajoreporte:null,
            botonExportar: true,
             

            dataSourceGrafico: null,
            dataSourcePendienteInterno: null,
            dataSourcePendienteCliente: null,
            frm_v_cliente: null,
            frm_v_servicio: null,
            frm_v_fechaelaboracion: null,
            frm_v_periodo: null,
            frm_v_estatus: null,
            frm_v_grafica: null,
            frm_v_ot: null,
            frm_periodo: null,
            frm_cliente: null,
            frm_v_cumplimiento: null,
            frm_v_incumplimiento: null,
            dataSourceCliente: null,
            dataSourceCompany: [],
            dataSourceVisita: null,
            empresa: {},
            events: [],
            rolService: null,
            isVisible: false,
            message: '',
            type: 'info',

            popupVisible: false,
            popupTitle: "Detalle",
            loading: true,

            popupOptions: {
                title: 'Detalle de Reporte',
                showTitle: true,
                width: 1000,
                height: 900,
            },
            dataSourcePieDev: [{
                texto: 'Russia',
                valor: 12,
            }, {
                texto: 'Canada',
                valor: 7,
            }, {
                texto: 'USA',
                valor: 7,
            }],
        };
    },
    created() {
        this.reportesService = new Reporteservice();
        this.periodoService = new Periodoservice();
        
    },
    mounted() {
        this.ObtienePeriodo();
        this.ObtieneReporte();
    },
    methods: {
         ObtienePeriodo() {
            
        this.periodoService.getPeriodo().then(data => this.dataSourcePeriodo = data);
         
        
        },
         
        async ObtieneReporte() {
             
            let err = 0;
           
            if (err == 0 && this.frm_periodo === null || this.frm_periodo === 0 || this.frm_periodo === 'undefined') {
                this.type = 'success';
                 this.message = 'Ingrese el Periodo para Avanzar';
                this.isVisible = true;
                
                
                    notify({
                        message: 'Ocurrio un error',
                    }, this.type, 4000);
                 
                err = 1;
            }


            if (err == 0) {

                let idusuario = JSON.parse(Cookies.get("ets_user_ec")).id;//1;
                let idcliente = JSON.parse(Cookies.get("ets_user_ec")).clienteid;//1;
               
                let myJSON = JSON.stringify({
                    pusuarioid: idusuario,
                    pperiodoid: this.frm_periodo,
                    pfechainicio: '2024-01-01',
                    pfechafin: '2024-01-01',
                    pclienteid: idcliente,
                });

                this.reportesService.getReporteEspecifico(myJSON).then(async data => {

                    this.dataSourceCompany = data;

                    this.botonExportar = await this.generategrap(data);
                    /*
                    const CHART_COLORS = {
                            green: 'rgb(96, 178, 13)',
                            blue: 'rgb(51, 141, 255)' 
                        };

                        var ctxchart = document.getElementById("graficas");
                
                        this.dataSourceCompany.forEach((element) => {
                            var valuesChart = JSON.parse(element.json_graficaentregajson);
                            var labelsChart = [];
                            var dataChart = [];
                            valuesChart.forEach(label => {
                                labelsChart.push(label.texto);
                                dataChart.push((label.valor));
                            });

                             
                             

                            var idChart = "myChart" + element.id.toString();
                            var ctx = document.createElement("canvas");
                            ctx.setAttribute("id", idChart);
                            new Chart(ctx, {
                                type: 'pie',
                                data: {
                                    labels: labelsChart,
            
                                    datasets: [{
                                        label: '# of Tomatoes',
                                        data: dataChart,
                                        backgroundColor: Object.values(CHART_COLORS),
                                        // borderWidth: 1
                                    } ,
                                    
                                   

                                    ]
                                },
                                
                            });
                            ctxchart.appendChild(ctx);
                        });*/

                });
                 

            }
        },

        exportar(e) {

            
            this.ordentrabajoreporte = "myChart" + e.row.data.id;
           
            //"myChart" + dataR.id.toString();
             
            this.dataSourceGrafico = JSON.parse(e.row.data.json_graficaentregajson);
            this.dataSourcePendienteInterno = JSON.parse(e.row.data.json_pendiente_interno);
            this.dataSourcePendienteCliente = JSON.parse(e.row.data.json_pendiente_cliente);
            this.frm_v_cliente = e.row.data.cliente;
            this.frm_v_servicio = e.row.data.servicio;
            this.frm_v_fechaelaboracion = e.row.data.fechaelaboracion;
            this.frm_v_periodo = e.row.data.periodo;
            this.frm_v_ot = e.row.data.ot;
            this.frm_v_estatus = e.row.data.estatus;
            this.frm_v_grafica = e.row.data.json_graficaentregajson;
            this.frm_v_cumplimiento= e.row.data.porcentaje_total;
            this.frm_v_incumplimiento= e.row.data.porcentaje_pendiente;

             setTimeout(() => {
                this.generatePDF();
            }, 3000);
            
        },
        generategrap(data) {

            const CHART_COLORS = {
                green: 'rgb(96, 178, 13)',
                blue: 'rgb(51, 141, 255)' 
            };

            var ctxchart = document.getElementById("graficas");
            var graficosRenderizados = {};
            var num = 0;
            var numGraficos = data.length;

      
            data.forEach((element) => {
                
                var valuesChart = JSON.parse(element.json_graficaentregajson);
                var labelsChart = [];
                var dataChart = [];
                valuesChart.forEach(label => {
                    labelsChart.push(label.texto);
                    dataChart.push((label.valor));
                });

                var idChart = "myChart" + element.id.toString();

                if(!graficosRenderizados[idChart]){
                    var ctx = document.createElement("canvas");
                    ctx.setAttribute("id", idChart);
                    new Chart(ctx, {
                        type: 'pie',
                        data: {
                            labels: labelsChart,
                            datasets: [{
                                label: '# of Tomatoes',
                                data: dataChart,
                                backgroundColor: Object.values(CHART_COLORS),
                                // borderWidth: 1
                            }]
                        },
                        options: {
                            responsive: true,
                            plugins: {
                                datalabels: {
                                    display: true,
                                    color: '#36A2EB'
                                },
                            }
                        }
                    });
                    graficosRenderizados[idChart] = true;
                    num ++;
                    ctxchart.appendChild(ctx);
                    
                }

                if(num === numGraficos){
                    console.log(graficosRenderizados);
                    return new Promise(resolve => {
                        setTimeout(() => {
                        resolve(false);
                        }, 2000);
                    });
                }



                

            });
        },

        generarTodo() {
            
            this.ordentrabajoreporte = '';
            this.exportarTodo();
        },
        exportarTodo() {
            //this.generategrap();

            setTimeout(() => {
                this.generatePDF();
            }, 2000);
        },
        generatePDF() {
             


            // TRABAJAR EXPORT PDF
            const doc = new jsPDF('portrait', 'mm', 'a4');

            //Centrar el texto
            let url = 'https://www.lmeraki.com/';
            var pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();


            let inicio = 32;
            let imgx = 75;
            doc.setFontSize(9);
            var ctxchart = document.getElementById("graficas");
            
            // SOLO UNO
            if(this.ordentrabajoreporte!=''){
                                
                                var img = new Image();
                                img.src = require("../assets/logo.png");
                                doc.addImage(img, "PNG", 15, 3, 40, 20);

                                doc.setFontSize(8);
                                doc.setFont('helvetica', 'bold');
                                doc.text("LUHOTT-MERAKI ASESORES AMBIENTALES, S.A. DE C.V.", pageWidth / 2, 7, { align: "center" });
                                doc.setFont('helvetica', 'normal');
                                doc.text("FRAY LUIS DE LEÓN 1730, COL. JARDÍN ESPAÑOL, MONTERREY, N.L., C.P. 64820", pageWidth / 2, 11, { align: "center" });
                                doc.text("TEL. 81 8387 4515 E-MAIL: info@lmeraki.com", pageWidth / 2, 15, { align: "center" });
                                doc.textWithLink("www.lmeraki.com", pageWidth / 2, 19, { align: "center", url: url });
                                doc.setFont('helvetica', 'bold');
                                doc.text("REPORTE DE AVANCE ESPECÍFICO", pageWidth / 2, 25, { align: "center" });
                               
                                var idChart = this.ordentrabajoreporte;

                                inicio = inicio + 15;
                                doc.rect(10, 38, 185, 10);
                                doc.rect(70, 38, 65, 10);
                                doc.text("Razón Social:", 12, 42, { align: "left" });
                                doc.text(this.frm_v_cliente, 12, 46, { align: "left" });

                                doc.text("Periodo:" +this.frm_v_periodo, 75, 44, { align: "left" });
                            
                                doc.text("Fecha Elaboración:", 138, 42, { align: "left" });
                                doc.text(this.frm_v_fechaelaboracion, 138, 46, { align: "left" });

                                
                                console.log('una a una ',idChart);
                                inicio = inicio + 10;
                                doc.text(this.frm_v_servicio + '(' + this.frm_v_ot + ')', 10, inicio, { align: "left" });
                                // obtener ot por servicio
                                inicio = inicio + 5;

                                doc.text("Estatus: " + this.frm_v_estatus, 10, inicio, { align: "left" });
                                
                                const canvas = document.getElementById(idChart);
                                const base64graph = canvas.toDataURL();
                                 
                               
                                var imgpie = new Image();
                                imgpie.src = base64graph;
                                doc.addImage(imgpie, "PNG", 10, imgx, 200, 100);
                                inicio = inicio + 120;
                                imgx = imgx + 100;

                                var cumplimiento = "Cumplimiento : " + this.frm_v_cumplimiento + "%";
                                var incumplimiento = "Incumplimiento : " + this.frm_v_incumplimiento + "%";
                                doc.text(cumplimiento, 30, inicio-30, { align: "left"});
                                doc.text(incumplimiento, 160, inicio-30, { align: "left"});
                                 
                                doc.text("- Entregables pendientes " + this.frm_v_cliente, 10, inicio, { align: "left" });
                                if (this.dataSourcePendienteCliente != null) {
                                    var valuesJsonPendiente =  this.dataSourcePendienteCliente;
                                    var tamanio = 0;
                                    valuesJsonPendiente.forEach(valueJson => {
                                        let splitText = doc.splitTextToSize('• ' + valueJson.descripcion, 180);
                                        inicio = tamanio > 350 ? inicio + 15 : inicio + 10;
                                        doc.text(splitText, 15, inicio, { align: "left" });

                                        tamanio = valueJson.descripcion.length;
                                       // console.log('APARTADOS CLIENTE',inicio + '-' + valueJson.descripcion);
                                        if(inicio>265){
                                            doc.addPage();
                                            inicio = 30; // Restart height position
                                            imgx = 75;     
                                        }
                                        
                                    });
                                }
                                
                                inicio = inicio + 10;
                               
                                if(inicio>290){
                                    doc.addPage();
                                    inicio = 30; // Restart height position
                                    imgx = 75;     
                                }
                                

                                doc.text("- Apartados técnicos pendientes", 10, inicio, { align: "left" });
                                //console.log('APARTADOS TECNICOS LABEL',inicio);
                                if (this.dataSourcePendienteInterno  != null) {
                                    var valuesJsonInterno =  this.dataSourcePendienteInterno;
                                  
                                    valuesJsonInterno.forEach(valueJson => {
                                        let splitText = doc.splitTextToSize('• ' + valueJson.descripcion, 180);
                                        inicio = inicio + 10;
                                        doc.text(splitText, 15, inicio, { align: "left" });

                                        if(inicio>265){
                                            doc.addPage();
                                            inicio = 30; // Restart height position
                                            imgx = 75;     
                                        }
                                        
                                    });
                                }
                                
                                //ctxchart.removeChild(canvas);

                                
                                doc.autoPrint();
                                window.open(
                                    URL.createObjectURL(doc.output("blob")),
                                    "_blank",
                                    "height=850,width=1600,scrollbars=yes,location=yes"
                                );
                            setTimeout(() => {
                                window.URL.revokeObjectURL(doc.output("bloburl"));
                            }, 100);
                           
                                
                            
            }
            else{
                        // CICLO
                        this.dataSourceCompany.forEach(
                            async (dataR, index) => {
                                var img = new Image();
                                img.src = require("../assets/logo.png");
                                doc.addImage(img, "PNG", 15, 3, 40, 20);

                                doc.setFontSize(8);
                                doc.setFont('helvetica', 'bold');
                                doc.text("LUHOTT-MERAKI ASESORES AMBIENTALES, S.A. DE C.V.", pageWidth / 2, 7, { align: "center" });
                                doc.setFont('helvetica', 'normal');
                                doc.text("FRAY LUIS DE LEÓN 1730, COL. JARDÍN ESPAÑOL, MONTERREY, N.L., C.P. 64820", pageWidth / 2, 11, { align: "center" });
                                doc.text("TEL. 81 8387 4515 E-MAIL: info@lmeraki.com", pageWidth / 2, 15, { align: "center" });
                                doc.textWithLink("www.lmeraki.com", pageWidth / 2, 19, { align: "center", url: url });
                                doc.setFont('helvetica', 'bold');
                                doc.text("REPORTE DE AVANCE ESPECÍFICO", pageWidth / 2, 25, { align: "center" });

                                var idChart = "myChart" + dataR.id.toString();
                               
                                inicio = inicio + 15;
                                doc.rect(10, 38, 185, 10);
                                doc.rect(70, 38, 65, 10);
                                doc.text("Razón Social:", 12, 42, { align: "left" });
                                doc.text(dataR.cliente, 12, 46, { align: "left" });

                                doc.text("Periodo:" + dataR.periodo, 75, 44, { align: "left" });
                            
                                doc.text("Fecha Elaboración:", 138, 42, { align: "left" });
                                doc.text(dataR.fechaelaboracion, 138, 46, { align: "left" });

                                inicio = inicio + 10;
                                doc.text(dataR.servicio + '(' + dataR.ot + ')', 10, inicio, { align: "left" });

                                inicio = inicio + 5;

                                doc.text("Estatus: " + dataR.estatus, 10, inicio, { align: "left" });

                                const canvas = document.getElementById(idChart);
                                const base64graph = canvas.toDataURL();


                                var imgpie = new Image();
                                imgpie.src = base64graph;
                                doc.addImage(imgpie, "PNG", 10, imgx, 200, 100);
                                inicio = inicio + 120;
                                imgx = imgx + 100;

                                var cumplimiento = "Cumplimiento : " + dataR.porcentaje_total + "%";
                                var incumplimiento = "Incumplimiento : " + dataR.porcentaje_pendiente + "%";
                                
   
                                doc.text(cumplimiento, 30, inicio-30, { align: "left"});
                                doc.text(incumplimiento, 160, inicio-30, { align: "left"});

                                doc.text("- Entregables pendientes " + dataR.cliente, 10, inicio, { align: "left" });
                                if (dataR.json_pendiente_cliente != null) {
                                    var valuesJsonPendiente = JSON.parse(dataR.json_pendiente_cliente);
                                    var tamanio = 0;
                                    //doc.text("Entregables pendientes " + dataR.cliente, 10, inicio, { align: "left" });


                                    valuesJsonPendiente.forEach(valueJson => {
                                        let splitText = doc.splitTextToSize('• ' + valueJson.descripcion, 180);
                                        inicio = tamanio > 350 ? inicio + 15 : inicio + 10;
                                        doc.text(splitText, 20, inicio, { align: "left" });

                                        tamanio = valueJson.descripcion.length;

                                        if(inicio>265){
                                            doc.addPage();
                                            inicio = 30; // Restart height position
                                            imgx = 75;     
                                        }

                                    });
                                }

                                inicio = inicio + 10;

                               
                                if(inicio>290){
                                    doc.addPage();
                                    inicio = 30; // Restart height position
                                    imgx = 75;     
                                }

                                doc.text("- Apartados técnicos pendientes", 10, inicio, { align: "left" });
                                if (dataR.json_pendiente_interno != null) {
                                    var valuesJsonInterno = JSON.parse(dataR.json_pendiente_interno);
                                    //doc.text("Apartados técnicos pendientes", 10, inicio, { align: "left" });


                                    valuesJsonInterno.forEach(valueJson => {
                                        let splitText = doc.splitTextToSize('• ' + valueJson.descripcion, 180);
                                        inicio = inicio + 10;
                                        
                                        doc.text(splitText, 20, inicio, { align: "left" });

                                        if(inicio>265){
                                            doc.addPage();
                                            inicio = 30; // Restart height position
                                            imgx = 75;     
                                        }
                                    });
                                }
                                ctxchart.removeChild(canvas);

                                inicio = inicio + 20;

                                if ((index + 1) < this.dataSourceCompany.length) {
                                    doc.addPage();
                                    inicio = 30; // Restart height position
                                    imgx = 75;
                                }
                            }
                        );

                    
                            doc.autoPrint();
                            window.open(
                                URL.createObjectURL(doc.output("blob")),
                                "_blank",
                                "height=850,width=1600,scrollbars=yes,location=yes"
                            );
                            setTimeout(() => {
                                window.URL.revokeObjectURL(doc.output("bloburl"));
                            }, 100);
                        
            }

        },



        logEvent(eventName) {
            this.events.unshift(eventName);
        },
    },
};
