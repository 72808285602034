
import {
  DxGroupItem,
  DxSimpleItem,
  DxLabel,
  DxItem,
  DxEmptyItem,
  DxForm,

} from 'devextreme-vue/form';


import {
  DxDataGrid,
  DxEditing,
  DxColumn,
  DxPaging,
  DxFilterRow,
  DxSearchPanel,
  DxScrolling,
  DxPager,
  DxLookup,
  DxGroupPanel,
  DxFormItem,
  DxValidationRule,
  DxButton,

} from 'devextreme-vue/data-grid';

import {
  DxGantt,
  DxTasks,
  DxDependencies,
 
  DxValidation,
  DxToolbar,
  DxHeaderFilter,
  DxStripLine,
  DxContextMenu,
} from 'devextreme-vue/gantt';

import 'devexpress-gantt/dist/dx-gantt.css';
//import DxButton from 'devextreme-vue/button';
import DxPopup, { DxToolbarItem } from 'devextreme-vue/popup';
import DxScrollView from 'devextreme-vue/scroll-view';
import DxDateBox from 'devextreme-vue/date-box';
import DxTextArea from 'devextreme-vue/text-area';
import DxSelectBox from 'devextreme-vue/select-box'

import DxCheckBox from 'devextreme-vue/check-box';
import axios from 'axios';
import DxTabPanel from "devextreme-vue/tab-panel";
import { customers } from './../data/dataperfil.js';
import { DxToast } from 'devextreme-vue/toast';
import 'devextreme-vue/text-area';
import notify from 'devextreme/ui/notify';
import Cookies from "js-cookie";
import auth from "../auth";
window.Cookies = Cookies;

import OrdenTrabajoResultadoService from '../services/ordentrabajoresultado-service';
import OrdenTrabajoDocumentoService from '../services/ordentrabajodocumento-service';
import OrdenTrabajoProcedimientoMedidasService from '../services/ordentrabajoprocedimientomedidas-service';
import OrdenTrabajoProcedimientoTerminosService from '../services/ordentrabajoprocedimientoterminos-service';
import OrdenTrabajoProcedimientoCondicionalesService from '../services/ordentrabajoprocedimientocondicionales-service';
import OrdenTrabajoProcedimientoProgramasService from '../services/ordentrabajoprocedimientoprogramas-service';
import OrdenTrabajoEtapaService from '../services/ordentrabajoetapa-service';
import TipoDocumentoService from '../services/tipodocumento-service';
import EstatusService from '../services/estatus-service';
import OrdenTrabajoPlanService from '../services/ordentrabajoplan-service';
import ResponsabilidadEntregaService from '../services/responsabilidadentrega-service';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver-es';
import { exportGantt as exportGanttToPdf } from 'devextreme/pdf_exporter';


import { DxFileUploader } from 'devextreme-vue/file-uploader';
import { DxTextBox } from 'devextreme-vue/text-box';
import moment from 'moment';
const sendRequest = function (value) {
  const validEmail = 'test@dx-email.com';
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(value === validEmail);
    }, 1000);
  });
};
const ganttRef = 'gantt';
export default {
  components: {
    DxGroupItem,
    DxSimpleItem,
    DxLookup,
    //DxEmailRule,
    DxDataGrid,
    DxTabPanel,
    DxItem,
    DxToast,
    DxEditing,
    DxColumn,
    DxPaging,
    DxForm,
    DxFilterRow,
    DxSearchPanel,
    DxScrolling,
    DxPager,
    DxLabel,
    DxGroupPanel,

    DxFormItem,
    DxSelectBox,


    DxFileUploader,
    DxTextBox,
    DxValidationRule,

    DxEmptyItem,
    DxButton,

    DxGantt,
    DxTasks,
    DxDependencies,
    
    DxValidation,
    DxToolbar,
    DxHeaderFilter,
    DxStripLine,
    DxContextMenu,
    DxCheckBox,
 
        DxPopup,
        DxScrollView,
        DxToolbarItem,
        DxDateBox,
        DxTextArea,
 
  },
  data() {
    return {


      ganttRef,
      loading: true,
      tasks: null,
      taskTitlePosition: 'none',
      dependencies: null,
      resources: null,
      resourceAssignments: null,

      fechaInicio: null,
      fechaFinal: null,
      fechaActual: null,
      tipoEscala: "weeks",
      taskColors: null,
      firstTaskStartDate: null,
      //showResources: false,

      contextMenuItems: [
        'addTask',
        'taskdetails',
        'deleteTask',
      ],
      datosEscala: [
        {nombre: "Horas", campo: "hours"},
        {nombre: "Dias", campo: "days"},
        {nombre: "Semanas", campo: "weeks"},
        {nombre: "Meses", campo: "months"},
        {nombre: "Años", campo: "years"},
      ],

      dataSourceResponsabilidadEntrega: null,


      dataSourceVigencia: null,
      dataSourceComplementaria: null,
      dataSourceResolutivo:null,
      
      com_popupVisible : false,
      com_id:null,
      com_informacion:null,
      com_fecharegistro:null,

      res_fecharesolutivo :null,
      res_vigenciaresolutivo :null,
      res_fechavigenciaresolutivo :null,

      com_aviso_popupVisible : false,
      est_complementaria: 'N',
      est_resolutivo:'N',
      popup_aviso_Options: 'Aviso',
      

      ld_popupVisible:false,
      li_popupVisible:false,
      v_li_id: null, 
      v_li_aplica: null, 
      v_li_entregado : null,
      v_li_recibio : null,
      v_li_fechaentrega : null,
      v_li_fechacompromisoentrega : null,
      v_li_responsabilidadentregaid : null,
      c_li_responsabilidadentregaid : null,
      v_li_observaciones : null,
      v_li_descripcion : null,

      v_ld_id: null, 
      v_ld_entregado : null,
      v_ld_fechaentrega : null,
      v_ld_aplica : null,
      v_ld_responsabilidadentregaid : null,
      c_ld_responsabilidadentregaid : null,
      v_ld_descripcion : null,
      popup_ld_OptionsListado: 'Edición Resultados - Documentación',
      
      popup_li_OptionsListado: 'Edición Resultados - Información',


      dataSourceResponsable: null,
      dataSourceResponsableUsuario: null,
      popupOptions_edicion_resultado: 'Edición Responsable',

      dataSourceEstatus: null,
      dataSourceCambioEstatus: null,
      dataSource: customers,
      dataSourceEtapaCierre: null,
      dataSourceEntrega: null,
      dataSourceListados: null,
      dataSourceDocumentacion: null,
      dataSourceEtapa: null,
      dataSourceHistorial: null,
      dataSourceDocumento: null,
      dataSourceAccion: null,
      dataSourceTipoMedida: null,
      dataSourceFactorAmbiental: null,
      dataSourceMedidasMitigacion: null,
      dataSourceMedidasSeguimiento: null,
      dataIdAccionSeleccionado: null,
      dataSourceTermino: null,
      dataSourceCondicion: null,
      dataSourcePrograma: null,
      showInfo: true,
      showNavButtons: true,
      isVisible: false,
      message: '',
      type: 'info',
      popupOptions_edicion_resolutivo: {
        title: 'Edición Resolutivo',
        showTitle: true,
        width: 650,
        height: 250,
      },
      popupOptions_edicion_complementario: {
        title: 'Edición Información Complementaria',
        showTitle: true,
        width: 400,
        height: 350,
      },
      popupOptionsListado: {
        title: 'Resultados',
        showTitle: true,
        width: 650,
        height: 550,
      },
      popupOptions: {
        title: 'Resultados',
        showTitle: true,
        width: 650,
        height: 500,
      }, popupOptions2: {
        title: 'Resultados',
        showTitle: true,
        width: 400,
        height: 500,
      },
      popupOptions3: {
        title: 'Cambio Etapa',
        showTitle: true,
        width: 400,
        height: 200,
      },
      popupOptionsEstatus: {
        title: 'Estatus',
        showTitle: true,
        width: 400,
        height: 200,
      },
      valetapa: 0,
      valcierre: '',
      valtipodocumento: 0,
      valnombredocumento: '',
      columns: ['CompanyName', 'City', 'State', 'Phone', 'Fax'],
      customer: null,//service.getCustomer(),
      buttonOptions: {
        text: 'Actualizar Orden Trabajo',
        type: 'success',
        useSubmitBehavior: true,
      },
      readonlyOptions: {
        disabled: true,
      },
      passwordOptions: {
        mode: 'password',
      },
      dateBoxOptions: {
        invalidDateMessage:
          'Campos tipo fecha debe tener el siguiente formato: MM/dd/yyyy',
      },
      checkBoxOptions: {
        text: 'I agree to the Terms and Conditions',
        value: false,
      },
      phoneEditorOptions: {
        mask: '+1 (X00) 000-0000',
        maskRules: {
          X: /[02-9]/,
        },
        maskInvalidMessage: 'The phone must have a correct USA phone format',
      },
      currencyOptions: {
        mask: '#,##0.00',
        maskInvalidMessage: 'The phone must have a correct USA phone format',

      },
      esCampoRequerido: true,
      esCampoRequerido2: true,
      opcionesItem: {
        width: '200%',
      },

      maxDate: new Date().setFullYear(new Date().getFullYear() - 21),
      namePattern: /^[^0-9]+$/,
      cityPattern: /^[^0-9]+$/,
      phonePattern: /^[02-9]\d{9}$/,
      numsPattern: /[0-9]/,
      vidot: null,
      vidotresponsable: null,
      backendURL: process.env.VUE_APP_APIURL,
      exportButtonOptions: {
        hint: 'Exportar como PDF',
        icon: 'exportpdf',
        stylingMode: 'text',
        onClick: () => {
          this.exportGantt();
        },
      },
      exportXLSXButtonOptions: {
        hint: 'Exportar como Excel',
        icon: 'exportxlsx',
        stylingMode: 'text',
        onClick: () => {
          this.exportGanttExcel();
        },
      },
    };
  },
  computed: {
    gantt() {
      return this.$refs[ganttRef].instance;
    },
  },
  created() {
    this.ordentrabajoresultadoService = new OrdenTrabajoResultadoService();
    this.ordentrabajoetapaService = new OrdenTrabajoEtapaService();
    this.ordentrabajodocumentoService = new OrdenTrabajoDocumentoService();
    this.ordentrabajoprocedimientomedidasService = new OrdenTrabajoProcedimientoMedidasService();
    this.ordentrabajoprocedimientoterminosService = new OrdenTrabajoProcedimientoTerminosService();
    this.ordentrabajoprocedimientocondicionalesService = new OrdenTrabajoProcedimientoCondicionalesService();
    this.ordentrabajoprocedimientoprogramasService = new OrdenTrabajoProcedimientoProgramasService();
    this.ordentrabajoplanService = new OrdenTrabajoPlanService();
    this.estatusService = new EstatusService();
    this.tipodocumentoService = new TipoDocumentoService();
    this.responsabilidadentregaService = new ResponsabilidadEntregaService();
    this.vidot = this.$route.params.id;

  },
  mounted() {


    this.loadDataInfo();
  },
  methods: {
    handleValueChange_li_aplica(e) {
      if (e.value == true) {
        this.v_li_aplica = false;
      }
      
      },
      handleValueChange_li_noaplica(e) {

      
      if (e.value == true) {
        this.v_li_entregado = false;
        
        this.v_li_recibio ='';
        this.v_li_fechaentrega ='';
        this.v_li_fechacompromisoentrega ='';
        this.v_li_observaciones='';
      }
     

      },
      handleValueChange_ld_aplica(e) {
      if (e.value == true) {
        this.v_ld_aplica = false;
      }
      
      },
      handleValueChange_ld_noaplica(e) {

      
      if (e.value == true) {
        this.v_ld_entregado = false;
        
      
        this.v_ld_fechaentrega ='';
       
      }
     

      },

    async exportGanttExcel(){

      //Obtenemos los datos de las tareas
      var ganttData = this.gantt._tasks;

      //Creamos nuestro workbook y worksheet
      var workbook = new ExcelJS.Workbook();
      var worksheet = workbook.addWorksheet("GanttData");
      
      
      var excelData = ganttData.map((task) => {
        var formattedStartDate = this.formatoFecha(task.start);
        var formattedEndDate = this.formatoFecha(task.end);

        return [
            task.title,
            formattedStartDate,
            formattedEndDate,
            // Add more fields as needed
        ];
      });

      var realData = ganttData.map((task) => {
        return [
            task.title,
            task.start,
            task.end,
           ];
      });

      this.taskColors = this.generateTaskColors(realData.length);

      // Add columns for months and apply styling to the timeline
      this.addMonthColumnsExcelJS(worksheet, realData);
      
      worksheet.addTable({
        name: 'GanttDataTable',
        ref: 'A8:C' + (ganttData.length + 1), // Assuming data starts from row 1
        headerRow: true,
        totalsRow: false,
        style: {
            theme: 'TableStyleLight1', // Choose a theme as needed
            showRowStripes: true,
        },
        columns: [
            { name: 'Tarea', filterButton: true, width: 80 },
            { name: 'Fecha Inicio', filterButton: true, width: 40 },
            { name: 'Fecha Final', filterButton: true, width: 40 },
            // Add more columns as needed
        ],
        rows: excelData, // Add row definitions
      });
      

      // Set the bold style for each header cell individually
      worksheet.getRow(8).eachCell({ includeEmpty: true }, (cell) => {
          cell.style = { 
              font: { bold: true },
              fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFD3D3D3' } },
              alignment: { horizontal: 'center' }
          };
      });
      worksheet.getColumn(1).width = 50;
      worksheet.getColumn(2).width = 15;
      worksheet.getColumn(3).width = 15;

      worksheet.mergeCells('B2:I2');
      const cellB2 = worksheet.getCell('B2');
      cellB2.value = 'LUHOTT-MERAKI ASESORES AMBIENTALES, S.A. DE C.V.';
      cellB2.font = { bold: true };
      cellB2.alignment = { horizontal: 'center', vertical: 'middle' };
      worksheet.mergeCells('B3:I3');
      const cellB3 = worksheet.getCell('B3');
      cellB3.value = 'FRAY LUIS DE LEÓN 1730, COL. JARDÍN ESPAÑOL, MONTERREY, N.L., C.P. 64820';
      cellB3.alignment = { horizontal: 'center', vertical: 'middle' };
      worksheet.mergeCells('B4:I4');
      const cellB4 = worksheet.getCell('B4');
      cellB4.value = 'TEL. 81 8387 4515 E-MAIL: info@lmeraki.com';
      cellB4.alignment = { horizontal: 'center', vertical: 'middle' };
      worksheet.mergeCells('B5:I5');
      const cellB5 = worksheet.getCell('B5');
      cellB5.value = 'www.lmeraki.com';
      cellB5.alignment = { horizontal: 'center', vertical: 'middle' };

      var imageURL = require('@/assets/logo.png');
      const imageBuffer = await this.getBufferFromImagePath(imageURL);
      
      const imageId = workbook.addImage({
        buffer: imageBuffer,
        extension: 'png',
      });
      
      worksheet.addImage(imageId, {
        tl: { col: 0, row: 1 },
        ext: { width: 350, height: 100 }
      });

      // Save Excel file
      
      workbook.xlsx.writeBuffer().then((buffer) => {
          saveAs(new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }), "GanttData.xlsx");
      });


    },
    async getBufferFromImagePath(imagePath) {
      try {
        const response = await fetch(imagePath);
        const arrayBuffer = await response.arrayBuffer();
        return Buffer.from(arrayBuffer);
      } catch (error) {
        console.error('Error al leer la imagen:', error);
      }
    },
    formatoFecha(date) {
      var d = new Date(date);
      var day = d.getDate().toString().padStart(2, '0');
      var month = (d.getMonth() + 1).toString().padStart(2, '0'); // Adding 1 because months are 0-based
      var year = d.getFullYear();
      return day + '-' + month + '-' + year;
    },
    // Function to generate an array of task colors
    generateTaskColors(count) {
      var colors = [];
      for (var i = 0; i < count; i++) {
        // Generate a random color (you can replace this with your logic to get unique colors)
        var color = this.getRandomColor();
        colors.push(color);
      }
      return colors;
    },
    // Function to generate a random color
    getRandomColor() {
      var letters = '0123456789ABCDEF';
      var color = '';
      for (var i = 0; i < 6; i++) {
          color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    },
    // Function to add month columns and apply styling to the timeline using ExcelJS
    addMonthColumnsExcelJS(worksheet, excelData) {

      // Find the oldest and newest dates from ganttData
      var allDates = excelData.reduce((acc, task) => {
          return acc.concat(new Date(task[1]), new Date(task[2]));
      }, []);
      //console.log(allDates);

      this.firstTaskStartDate = new Date(Math.min.apply(null, allDates));
      var lastTaskEndDate = new Date(Math.max.apply(null, allDates));

      if(this.tipoEscala === 'months'){
        // Calculate the number of months between the oldest and newest dates
        var numberOfMonths = this.calculateMonthsDifference(this.firstTaskStartDate, lastTaskEndDate);

        var months = [];
        for (var i = 0; i <= numberOfMonths; i++) {
            var currentDate = new Date(this.firstTaskStartDate);
            currentDate.setMonth(this.firstTaskStartDate.getMonth() + i);

            // Handle potential overflow to the next year
            if (currentDate.getMonth() !== (this.firstTaskStartDate.getMonth() + i) % 12) {
                currentDate.setFullYear(this.firstTaskStartDate.getFullYear() + Math.floor((this.firstTaskStartDate.getMonth() + i) / 12));
                currentDate.setMonth((this.firstTaskStartDate.getMonth() + i) % 12);
            }

            months.push(this.getMonthLabel(currentDate));
        }

        months.forEach((month, index) => {
            var colIndex = index + 4; // Start from the 4th column
            // Add month label to the header row
            worksheet.getRow(8).getCell(colIndex).value = month;
            worksheet.getRow(8).getCell(colIndex).numFmt = '0';
          worksheet.getColumn(colIndex).width = 15;
            this.applyTimelineRowStyle(worksheet, colIndex, excelData);
            
        });

      } else if (this.tipoEscala === 'days'){
        var numberOfDays = this.calculateDaysDifference(this.firstTaskStartDate, lastTaskEndDate);
        var days = [];
        var millisecondsInDay = 24 * 60 * 60 * 1000;

        for ( i = 0; i <= numberOfDays; i++) {
            currentDate = new Date(this.firstTaskStartDate.getTime() + i * millisecondsInDay);

            // Agregar la cadena del día al array
            days.push(this.getDayLabel(currentDate));
        }

        days.forEach((day, index) => {
          var colIndex = index + 4;
          worksheet.getRow(8).getCell(colIndex).value = day;
          this.applyTimelineRowStyle(worksheet, colIndex, excelData);
        });


      } else {
        var numberOfWeeks = this.calculateWeeksDifference(this.firstTaskStartDate, lastTaskEndDate);
        var weeks = [];
        var millisecondsInWeek = 7 * 24 * 60 * 60 * 1000;
        for ( i = 0; i <= numberOfWeeks; i++) {
          currentDate = new Date(this.firstTaskStartDate.getTime() + i * millisecondsInWeek);
          // Agregar la cadena de la semana al array
          weeks.push(this.getWeekLabel(currentDate));
        }

        weeks.forEach((week, index) => {
          var colIndex = index + 4; 
          worksheet.getRow(8).getCell(colIndex).value = week;
          worksheet.getRow(8).getCell(colIndex).numFmt = '0';
          worksheet.getColumn(colIndex).width = 20;
          this.applyTimelineRowStyle(worksheet, colIndex, excelData);
          
        });
      }
      
    },
    calculateMonthsDifference(date1, date2) {
        var months;
        months = (date2.getUTCFullYear() - date1.getUTCFullYear()) * 12;
        months -= date1.getUTCMonth();
        months += date2.getUTCMonth();
        return months <= 0 ? 0 : months;
    },
    calculateWeeksDifference(date1, date2) {
        // Convert both dates to UTC to avoid timezone issues
        var utcDate1 = new Date(Date.UTC(date1.getFullYear(), date1.getMonth(), date1.getDate()));
        var utcDate2 = new Date(Date.UTC(date2.getFullYear(), date2.getMonth(), date2.getDate()));

        // Calculate the difference in milliseconds
        var differenceMs = utcDate2 - utcDate1;

        // Convert the difference to weeks
        var differenceWeeks = Math.floor(differenceMs / (1000 * 60 * 60 * 24 * 7));

        return differenceWeeks;
    },
    calculateDaysDifference(date1, date2) {
      var days;
      var differenceMs = date2.getTime() - date1.getTime();
      days = Math.floor(differenceMs / (1000 * 60 * 60 * 24));
      return days <= 0 ? 0 : days;
    },
    // Function to get Excel column name from index
    getExcelColumnName(index) {
      var columnName = '';
      while (index > 0) {
          var remainder = (index - 1) % 26;
          columnName = String.fromCharCode(65 + remainder) + columnName;
          index = (index - 1) / 26 | 0;
      }
      //console.log(columnName);
      return columnName || undefined;
    },
    // Function to get month label
    getMonthLabel(date) {
      var monthNames = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
      var d = new Date(date);
      return `${monthNames[d.getMonth()]} ${d.getFullYear().toString().substr(2)}`;
    },
    getWeekLabel(date) {
      var d = new Date(date);
      var weekNumber = this.getWeekNumber(d);
      var monthNames = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
      var monthName;
      var year = d.getFullYear();
      // Obtener el primer día de la semana
      var firstDayOfWeek = new Date(d);
      firstDayOfWeek.setDate(d.getDate() - d.getDay() + 1);

      // Obtener el último día de la semana
      var lastDayOfWeek = new Date(firstDayOfWeek);
      lastDayOfWeek.setDate(firstDayOfWeek.getDate() + 6);

      // Verificar si el primer día de la semana pertenece a otro mes
      if (firstDayOfWeek.getMonth() !== d.getMonth()) {
          // Si es así, significa que estamos en la última semana del mes anterior
          monthName = monthNames[firstDayOfWeek.getMonth()];
          year = firstDayOfWeek.getFullYear();
          weekNumber = this.getWeekNumber(firstDayOfWeek);
      } else if (lastDayOfWeek.getMonth() !== d.getMonth()) {
          // Si el último día de la semana pertenece a otro mes, estamos en la primera semana del mes siguiente
          monthName = monthNames[firstDayOfWeek.getMonth()];
          year = firstDayOfWeek.getFullYear();
          //weekNumber = 1;
      } else {
          // De lo contrario, estamos dentro del mes actual
          monthName = monthNames[d.getMonth()];
      }
      return `Sem ${weekNumber} ${monthName} ${year}`;
  },

  // Función auxiliar para obtener el número de semana en un año
  getWeekNumber(date) {
    var d = new Date(date);
    d.setHours(0, 0, 0, 0);
    var monthStart = new Date(d.getFullYear(), d.getMonth(), 1);
    var monthFirstDay = monthStart.getDay();
    var weekNumber = Math.ceil((d.getDate() + monthFirstDay) / 7);
    return weekNumber;
  },

  getDayLabel(date) {
      var d = new Date(date);
      //var dayOfWeek = d.toLocaleString('es-ES', { weekday: 'long' });
      // Convertir la primera letra del día de la semana a mayúscula
      //var capitalizedDayOfWeek = dayOfWeek.charAt(0).toUpperCase() + dayOfWeek.slice(1);
      var dayOfMonth = d.getDate();
      var monthNames = ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"];
      var monthName = monthNames[d.getMonth()];
      var year = d.getFullYear().toString().slice(-2);
      
      return `${dayOfMonth} ${monthName} ${year}`;
  },


    getMonthNumber(date) {
      var d = new Date(date);
      return d.getUTCMonth() + 1;
    },
    getFirstDayOfPreviousMonth(originalDate) {
      // Create a copy of the original date
      const previousMonth = new Date(originalDate);

      // Subtract one month from the original date
      previousMonth.setMonth(originalDate.getMonth() - 1);

      // If the resulting month is December of the previous year, adjust the year
      if (previousMonth.getMonth() === 11) {
        previousMonth.setFullYear(originalDate.getFullYear() - 1);
      }

      // Set the day to 1 to get the first day of the month
      previousMonth.setDate(1);

      return previousMonth;
    },
    getFirstDayOfPreviousWeek(originalDate) {
        // Create a copy of the original date
        const previousWeek = new Date(originalDate);

        // Subtract 7 days (1 week) from the original date
        previousWeek.setDate(originalDate.getDate() - 7);

        // Check if the resulting week belongs to the previous year
        if (previousWeek.getFullYear() < originalDate.getFullYear()) {
            // Adjust the year if necessary
            previousWeek.setFullYear(originalDate.getFullYear() - 1);
        }

        return previousWeek;
    },
    getMonthsFromFixedStartDate(date) {
      var fixedStartDate = this.getFirstDayOfPreviousMonth(this.firstTaskStartDate); 
      //console.log(fixedStartDate);
      var months = this.calculateMonthsDifference(fixedStartDate, date);
      return months;
    },
    getWeeksFromFixedStartDate(date) {
      var fixedStartDate = this.getFirstDayOfPreviousWeek(this.firstTaskStartDate); 
      //console.log(fixedStartDate);
      var weeks = this.calculateWeeksDifference(fixedStartDate, date);
      return weeks;
    },
    getDaysFromFixedStartDate(date) {
      var days = this.calculateDaysDifference(this.firstTaskStartDate, date);
      return days;
    },
    applyTimelineRowStyle(worksheet, colIndex, excelData) {
      for (var rowIndex = 2; rowIndex <= excelData.length + 1; rowIndex++) {
          var taskStart = new Date(excelData[rowIndex - 2][1]);
          var taskEnd = new Date(excelData[rowIndex - 2][2]);
          var colorP = 'FF228B22';
          var rowExcel = rowIndex + 7;
          var varStart = 0;
          var varEnd = 0;

          if(this.tipoEscala === 'months'){
            varStart = this.getMonthsFromFixedStartDate(taskStart);
            varEnd = this.getMonthsFromFixedStartDate(taskEnd);
          } else if (this.tipoEscala === 'days'){
            varStart = this.getDaysFromFixedStartDate(taskStart);
            varEnd = this.getDaysFromFixedStartDate(taskEnd);
          } else {
            varStart = this.getWeeksFromFixedStartDate(taskStart);
            varEnd = this.getWeeksFromFixedStartDate(taskEnd);
          }
          //var colorP = this.taskColors[rowIndex - 2];

          // Check if the column corresponds to the task timeline
          if (colIndex >= varStart + 3 && colIndex <= varEnd + 3) {
              // Adjust the start and end positions for the first and last tasks
              
              var startCol = Math.max(varStart + 3, colIndex);

              var endCol = Math.min(varEnd + 3, colIndex);

              for (var i = startCol; i <= endCol; i++) {
                  var cell = worksheet.getCell(`${this.getExcelColumnName(i)}${rowExcel}`);
                  cell.fill = {
                      type: 'pattern',
                      pattern: 'solid',
                      fgColor: { argb: colorP }, // Change the color as needed
                  };
              }
          }
      }
    },
    exportGantt() {
      console.log(this.gantt);
      exportGanttToPdf({
        component: this.gantt,
        createDocumentMethod: (args) => new jsPDF(args),
        landscape: 'true',
        exportMode: 'all',
        dateRange: "visible",
        margins: { left: 0, top: 180, right: 0, botoom: 0 }
      }).then((doc) => {
        /*
        doc.setProperties({
          title: 'Title',
          subject: 'This is the subject',
          author: 'James Hall',
          keywords: 'generated, javascript, web 2.0, ajax',
          creator: 'MEEE'
        });

        doc.setPage(1);
        doc.setFontSize(20);

        var img = new Image();
        img.src = require("../assets/logo.png");
        doc.addImage(img, "PNG", 0, 0, 290, 140);
        
        doc.setFontSize(12);
        doc.text("PLANTA:", 15, 168);
        doc.text("FECHA:", 560, 168);

        doc.text(this.pdfRazon ? this.pdfRazon : '', 85, 168);
        doc.text(this.pdfFechaInicio ? this.pdfFechaInicio : '', 625, 168);
        doc.text("PLAN DE TRABAJO", 300, 148);*/
        doc.save('gantt.pdf');
      });
    },

    onUploaded(e) {
      let id = this.vidot;
      let res = JSON.parse(e.request.responseText);
      let archivosubido = res.image_name;
      let idusuario = JSON.parse(Cookies.get("ets_user_ec")).id;//1;
      let myJSON = JSON.stringify({
        ptipodocumentoid: this.valtipodocumento,
        pnombredocumento: this.valnombredocumento,
        pruta: archivosubido,
        pordentrabajoid: id,
        pusuarioregistroid: idusuario
      });

      let err = 0;
      let msgerror = '';

      if (this.valtipodocumento == null || this.valtipodocumento == '' || this.valtipodocumento == 'undefined') {
        err = 1;
        msgerror = 'Ingresar tipo documento';
      }

      if (err == 0) {
        if (this.valnombredocumento == null || this.valnombredocumento == '' || this.valnombredocumento == 'undefined') {
          err = 1;
          msgerror = 'Ingresar nombre documento';
        }
      }

      if (err == 0) {
        this.ordentrabajodocumentoService.createOrdenTrabajoDocumento(myJSON).then(data => {

          if (data.err == 0) {
            this.type = 'success';
            this.message = 'Se ha actualizado el registro';
            this.isVisible = true;

            notify({
              message: this.message,
            }, this.type, 5000);
            this.popupVisible = false;
            this.valtipodocumento = null;
            this.valnombredocumento = null;
            this.loadDataInfo();
          } else {

            this.type = 'error';
            this.message = 'Ocurrio un error ' + data.msg;
            this.isVisible = true;
            notify({
              message: 'Ocurrio un error ' + data.msg,
            }, this.type, 4000);


          }
          //this.loadDataInfo();

        });
      } else {
        this.type = 'error';
        this.message = msgerror;
        this.isVisible = true;
        notify({
          message: this.message,
        }, this.type, 4000);

      }
    },
    onUploadError(e) {
      let xhttp = e.request;
      if (xhttp.status === 400) {
        e.message = e.error.responseText;
      }
      if (xhttp.readyState === 4 && xhttp.status === 0) {
        e.message = "Conexión rechazada";
      }
    },
    editListadoInformacion(e) {

        this.v_li_id = e.row.data.id;
        this.v_li_descripcion = e.row.data.descripcion;
        this.v_li_aplica = e.row.data.aplica;
        this.v_li_entregado = e.row.data.entregado ;
        this.v_li_recibio = e.row.data.recibio;
        this.v_li_fechaentrega = e.row.data.fechaentrega;
        this.v_li_fechacompromisoentrega = e.row.data.fechacompromisoentrega;
        this.v_li_responsabilidadentregaid = e.row.data.responsabilidadentregaid;
        this.c_li_responsabilidadentregaid = e.row.data.responsabilidadentregaid;
        this.v_li_observaciones = e.row.data.observaciones;
        this.li_popupVisible=true;

        },
    saveListadoInformacion() {
            let err = 0;
            let msgerror = '';
            let vid = this.v_li_id;
            let vresponsabilidadentregaid = (this.v_li_responsabilidadentregaid == '') ? 1 : this.v_li_responsabilidadentregaid;
            let vrecibio =  (this.v_li_recibio == '') ? '' : this.v_li_recibio;
            let vfechaentrega = (this.v_li_fechaentrega == '') ? '9999-01-01' : moment(this.v_li_fechaentrega).format('YYYY-MM-DD');
            let vfechacompromisoentrega = (this.v_li_fechacompromisoentrega == '') ? '9999-01-01' : moment(this.v_li_fechacompromisoentrega).format('YYYY-MM-DD');
            let vobservaciones= (this.v_li_observaciones == '') ? '' : this.v_li_observaciones;
            let ventregado = this.v_li_entregado == true ? 1 : 0;
            let vaplica = this.v_li_aplica == true ? 1 : 0;
            let idusuario = JSON.parse(Cookies.get("ets_user_ec")).id;//1;
            let myJSON;

            if(vaplica==0 && ventregado ==0){
              if(this.c_li_responsabilidadentregaid === vresponsabilidadentregaid){
                err = 1;
                msgerror = 'Seleccione No Aplica o Entrega';
                this.type = 'error';
                        this.message = msgerror;
                        this.isVisible = true;

                        notify({
                          message: this.message,
                        }, this.type, 9000);
              }
            }
            


              if(vaplica==1 || vaplica=='1'){
                myJSON = JSON.stringify({
                  pid: vid,
                  pentregado: 0,
                  pfechaentrega: '9999-01-01' ,
                  pfechacompromisoentrega: '9999-01-01',
                  pusuarioregistroid: idusuario,
                  pobservaciones: '',
                  precibio: '',
                  paplica: vaplica,
                  presponsabilidadentregaid : vresponsabilidadentregaid
                });
              } else {
                myJSON = JSON.stringify({
                  pid: vid,
                  pentregado: ventregado,
                  pfechaentrega: vfechaentrega,
                  pfechacompromisoentrega: vfechacompromisoentrega,
                  pusuarioregistroid: idusuario,
                  pobservaciones: vobservaciones,
                  precibio: vrecibio,
                  paplica: vaplica,
                  presponsabilidadentregaid : vresponsabilidadentregaid
                });
              }
             
      
          
 
 
            if (err == 0) {
              this.ordentrabajoresultadoService.updateOrdenTrabajoListadoInformacion(myJSON).then(data => {
                    if (data.err == 0) {
                      
                      this.type = 'success';
                      this.message = 'Se ha actualizado el registro';
                      this.isVisible = true;

                      notify({
                        message: this.message,
                      }, this.type, 9000);
                      this.li_popupVisible=false;
                      this.loadDataInfo();
                     
                    } else {

                      this.type = 'error';
                      this.message = 'Ocurrio un error ' + data.msg;
                      this.isVisible = true;
                      notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                          my: 'center top',
                          at: 'center top',
                        },
                      }, 'error', 9000);
                    }
                    //this.loadDataInfo();

                    });


            }  





    },

    editListadoDocumentacion(e) {

        this.v_ld_id = e.row.data.id;
        this.v_ld_descripcion = e.row.data.descripcion;
        this.v_ld_aplica = e.row.data.aplica;
        this.v_ld_entregado = e.row.data.entregado ;
        this.v_ld_fechaentrega = e.row.data.fechaentrega;
        this.v_ld_responsabilidadentregaid = e.row.data.responsabilidadentregaid;
        this.c_ld_responsabilidadentregaid = e.row.data.responsabilidadentregaid;
        this.ld_popupVisible=true;

        },
      
    saveListadoDocumentacion() {
            let err = 0;
            let msgerror = '';
            let vid = this.v_ld_id;
            let vresponsabilidadentregaid = (this.v_ld_responsabilidadentregaid == '') ? 1 : this.v_ld_responsabilidadentregaid;
            let vfechaentrega = (this.v_ld_fechaentrega == '') ? '9999-01-01' : moment(this.v_ld_fechaentrega).format('YYYY-MM-DD');
            let ventregado = this.v_ld_entregado == true ? 1 : 0;
            let vaplica = this.v_ld_aplica == true ? 1 : 0;
            //let idusuario = JSON.parse(Cookies.get("ets_user_ec")).id;//1;
            let myJSON;

            if(vaplica==0 && ventregado ==0){
              if(this.c_ld_responsabilidadentregaid === vresponsabilidadentregaid){
                err = 1;
                msgerror = 'Seleccione No Aplica o Entrega';
                this.type = 'error';
                        this.message = msgerror;
                        this.isVisible = true;

                        notify({
                          message: this.message,
                        }, this.type, 9000);
              }
            }
            


              if(vaplica==1 || vaplica=='1'){
                myJSON = JSON.stringify({
                  pid: vid,
                  pentregado: 0,
                  pfechaentrega: '9999-01-01' ,
                  paplica: vaplica,
                  presponsabilidadentregaid : vresponsabilidadentregaid

                });
              } else {
                myJSON = JSON.stringify({
                  pid:vid,
                  pentregado: ventregado,
                  pfechaentrega: vfechaentrega,
                  paplica: 0,
                  presponsabilidadentregaid : vresponsabilidadentregaid
                });
              }
             
       
 
            if (err == 0) {

              this.ordentrabajoresultadoService.updateOrdenTrabajoListadoDocumentacion(myJSON).then(data => {
                   
                      if (data.err == 0) {
                       
                        this.type = 'success';
                        this.message = 'Se ha actualizado el registro';
                        this.isVisible = true;

                        notify({
                          message: this.message,
                        }, this.type, 9000);

                       


                        this.ld_popupVisible=false;
                        this.loadDataInfo();
                      } else {
                        this.isVisible = false;
                        this.type = 'error';
                        this.message = 'Ocurrio un error ' + data.msg;
                        this.isVisible = true;
                        notify({
                          message: 'Ocurrio un error ' + data.msg,
                          position: {
                            my: 'center top',
                            at: 'center top',
                          },
                        }, 'error', 9000);
                      }
                      

                      });


            }  





    },

    editRegisterCambioEtapa(e) {
      let id = this.vidot;
      let idusuario = JSON.parse(Cookies.get("ets_user_ec")).id;//1;
      let myJSON = JSON.stringify({
        pid: id,
        pusuario: idusuario,
        petapaid: e.data.etapaid,
      });

      this.ordentrabajoresultadoService.getOrdenTrabajoCierreEtapa(myJSON).then(data => {

        if (data.err == 0) {

          notify({
            message: 'OT Etapa ha sido actualizada',
          }, 'success', 9000);
          //this.$router.push({ name: 'ordentrabajoresultadoconguia', params: { id: this.vidot } });
          this.loadDataInfo();
        } else {

          notify({
            message: 'Ocurrio un error ' + data.msg,
            position: {
              my: 'center top',
              at: 'center top',
            },
          }, 'error', 9000);
          // document.location.reload();

        }


      });


    },
    editRegisterCambioEstatus(e) {

      let idusuario = JSON.parse(Cookies.get("ets_user_ec")).id;//1;
      let myJSON = JSON.stringify({
        pid: this.vidot,
        pusuario: idusuario,
        pobservacion: e.data.observacion,
        pestatusid: e.data.estatusid,
      });
      
      this.ordentrabajoresultadoService.getOrdenTrabajoCierre(myJSON).then(data => {

        if (data.err == 0) {
          console.log('CAMBIO ESTATUS',data);
          if(data.complementaria == 1){
            this.est_complementaria='S';
            this.est_resolutivo='N';
            this.com_aviso_popupVisible=true;
          }else{
            if(data.resolutivo == 1){
              this.est_complementaria='N';
            this.est_resolutivo='S';
            this.com_aviso_popupVisible=true;
            }
          }
          notify({
            message: 'OT ha sido actualizada',
          }, 'success', 9000);
          this.loadDataInfo();
        } else {

          notify({
            message: 'Ocurrio un error ' + data.msg,
            position: {
              my: 'center top',
              at: 'center top',
            },
          }, 'error', 9000);
          // document.location.reload();

        }


      });

    },

    onAccionSeleccionada({ selectedRowsData }) {
      const accion = selectedRowsData[0];

      this.dataIdAccionSeleccionado = accion.id;

      let myJSONID = JSON.stringify({
        pid: accion.id,
      });

      this.ordentrabajoprocedimientomedidasService.getAccionMedida(myJSONID).then(data => {
        this.dataSourceMedidasMitigacion = data;
      });

    },
    agregarAccion(e) {
      let myJSONID = JSON.stringify({
        pordentrabajoid: this.vidot,
        pfactorambientalid: e.data.factorambientalid,
        pcomponentes: e.data.componentes,
        pacciones: e.data.acciones,
        petapas: e.data.etapas
      });

      this.ordentrabajoprocedimientomedidasService.createAccion(myJSONID).then(data => {
        if (data.err == 0) {
          notify({
            message: 'Accion ha sido actualizada',
          }, 'success', 9000);
          this.loadDataAccion();
        } else {

          notify({
            message: 'Ocurrio un error ' + data.msg,
            position: {
              my: 'center top',
              at: 'center top',
            },
          }, 'error', 9000);

        }

      });
    },
    eliminarAccion(e) {

      let myJSONID = JSON.stringify({
        pid: e.data.id
      });

      this.ordentrabajoprocedimientomedidasService.deleteAccion(myJSONID).then(data => {

        if (data.err == 0) {
          notify({
            message: 'La acción ha sido eliminada',
          }, 'success', 9000);
          this.loadDataAccion();
        } else {

          notify({
            message: 'Ocurrio un error ' + data.msg,
            position: {
              my: 'center top',
              at: 'center top',
            },
          }, 'error', 9000);

        }

      });

    },
    editarAccion(e) {

      let myJSONID = JSON.stringify({
        pid: e.data.id,
        pordentrabajoid: this.vidot,
        pfactorambientalid: e.data.factorambientalid,
        pcomponentes: e.data.componentes,
        pacciones: e.data.acciones,
        petapas: e.data.etapas
      });


      this.ordentrabajoprocedimientomedidasService.updateAccion(myJSONID).then(data => {

        if (data.err == 0) {
          notify({
            message: 'La acción ha sido actualizada',
          }, 'success', 9000);
          this.loadDataAccion();
        } else {

          notify({
            message: 'Ocurrio un error ' + data.msg,
            position: {
              my: 'center top',
              at: 'center top',
            },
          }, 'error', 9000);

        }

      });
    },

    loadDataAccion() {
      /**
      let myJSON = JSON.stringify({
        pid: this.vidot
      });

      this.ordentrabajoprocedimientomedidasService.getAccion(myJSON).then(data => {
        this.dataSourceAccion = data;
      });
      **/
    },
    agregarMedida(e) {
      let myJSONID = JSON.stringify({
        pordentrabajoestudioaccionid: this.dataIdAccionSeleccionado,
        ptipomedidaid: e.data.tipomedidaid,
        pmedida: e.data.medida
      });

      this.ordentrabajoprocedimientomedidasService.createMedida(myJSONID).then(data => {
        if (data.err == 0) {
          notify({
            message: 'Medida ha sido actualizada',
          }, 'success', 9000);
          this.loadDataMedida();
        } else {

          notify({
            message: 'Ocurrio un error ' + data.msg,
            position: {
              my: 'center top',
              at: 'center top',
            },
          }, 'error', 9000);

        }

      });
    },
    eliminarMedida(e) {
      let myJSONID = JSON.stringify({
        pid: e.data.id
      });

      this.ordentrabajoprocedimientomedidasService.deleteMedida(myJSONID).then(data => {

        if (data.err == 0) {
          notify({
            message: 'La medida ha sido eliminada',
          }, 'success', 9000);
          this.loadDataMedida();
        } else {

          notify({
            message: 'Ocurrio un error ' + data.msg,
            position: {
              my: 'center top',
              at: 'center top',
            },
          }, 'error', 9000);

        }

      });

    },

    deleteProcedureDocumento(e) {
      let myJSON = JSON.stringify({
        pid: e.data.id,
      });

      
      this.ordentrabajodocumentoService.deleteOrdenTrabajoDocumento(myJSON).then(data => {
        if (data.err == 0) {
          this.type = 'success';
          this.message = 'Se ha eliminado el registro';
          this.isVisible = true;
          this.loadDataInfo();
        } else {

          this.type = 'error';
          this.message = 'Ocurrio un error ' + data.msg;
          this.isVisible = true;
        }
      });
    },


    editarMedida(e) {

      let myJSONID = JSON.stringify({
        pid: e.data.id,
        ptipomedidaid: e.data.tipomedidaid,
        pmedida: e.data.medida
      });

      this.ordentrabajoprocedimientomedidasService.updateMedida(myJSONID).then(data => {

        if (data.err == 0) {
          notify({
            message: 'La medida ha sido actualizada',
          }, 'success', 9000);
          this.loadDataMedida();
        } else {

          notify({
            message: 'Ocurrio un error ' + data.msg,
            position: {
              my: 'center top',
              at: 'center top',
            },
          }, 'error', 9000);

        }

      });
    },
    loadDataMedida() {

      let myJSONID = JSON.stringify({
        pid: this.dataIdAccionSeleccionado,
      });

      this.ordentrabajoprocedimientomedidasService.getAccionMedida(myJSONID).then(data => {
        this.dataSourceMedidasMitigacion = data;
      });
    },

    editResolutivo(e) {


          
          let myJSON = JSON.stringify({
            pid: e.data.id,
            pfecharesolutivo: moment(e.data.fecharesolutivo).format('YYYY-MM-DD'),  
            pvigenciaresolutivo: e.data.vigenciaresolutivo,
         
          });

          
          this.ordentrabajoresultadoService.cambioResolutivo(myJSON).then(() => {
            this.type = 'success';
            this.message = 'Se ha actualizado el registro';
            this.isVisible = true;
            this.loadDataInfo();
          });
          },


    createComplementaria(e) {

    
      let idusuario = JSON.parse(Cookies.get("ets_user_ec")).id; 
        let myJSON = JSON.stringify({
          pordentrabajoid: this.vidot,
          pinformacion: e.data.informacion,
          pusuarioregistroid: idusuario,
          pentregado: e.data.entregado,
        });


        this.ordentrabajoresultadoService.altaComplementaria(myJSON).then(() => {
          this.type = 'success';
          this.message = 'Se ha insertado el registro';
          this.isVisible = true;
          this.loadDataInfo();
        });
        },
    editComplementaria(e) {

     
        let idusuario = JSON.parse(Cookies.get("ets_user_ec")).id; 
          let myJSON = JSON.stringify({
            pid: e.data.id,
            pordentrabajoid: this.vidot,
            pinformacion: e.data.informacion,
            pusuarioregistroid: idusuario,
          pentregado: e.data.entregado,
          });


          this.ordentrabajoresultadoService.cambioComplementaria(myJSON).then(() => {
            this.type = 'success';
            this.message = 'Se ha actualizado el registro';
            this.isVisible = true;
            this.loadDataInfo();
          });
    },
    deleteComplementaria(e) {

     
      
        let myJSON = JSON.stringify({
          pid: e.data.id,
          
        });


        this.ordentrabajoresultadoService.bajaComplementaria(myJSON).then(() => {
          this.type = 'success';
          this.message = 'Se ha eliminado el registro';
          this.isVisible = true;
          this.loadDataInfo();
        });
      },
    editarMedidaSeguimiento(e) {

      let myJSONID = JSON.stringify({
        pid: e.data.id,
        pfechalimite: moment(e.data.fechalimite).format('YYYY-MM-DD'),
        pcompletado: e.data.completado,
        pobservacion: e.data.observacion
      });

      this.ordentrabajoprocedimientomedidasService.updateMedidaSeguimiento(myJSONID).then(data => {

        if (data.err == 0) {
          notify({
            message: 'La medida seguimiento ha sido actualizada',
          }, 'success', 9000);
          this.loadDataMedidaSeguimiento();
        } else {

          notify({
            message: 'Ocurrio un error ' + data.msg,
            position: {
              my: 'center top',
              at: 'center top',
            },
          }, 'error', 9000);

        }

      });
    },
    loadDataMedidaSeguimiento() {
      let myJSON = JSON.stringify({
        pid: this.vidot
      });

      this.ordentrabajoprocedimientomedidasService.getMedidaSeguimiento(myJSON).then(data => {
        this.dataSourceMedidasSeguimiento = data;

      })
    },
    agregarTermino(e) {
      let idusuario = JSON.parse(Cookies.get("ets_user_ec")).id;
      let myJSONID = JSON.stringify({
        pordentrabajoid: this.vidot,
        pusuarioregistroid: idusuario,
        pnombre: e.data.nombre,
        pdescripcion: e.data.descripcion,
        pfechalimite: moment(e.data.fechalimite).format('YYYY-MM-DD'),
        pcompletado: e.data.completado,
        pobservacion: e.data.observacion,
        pevidencia_nombredocumento: e.data.evidencia_nombredocumento,
        pevidencia_ruta: e.data.evidencia_ruta
      });

      this.ordentrabajoprocedimientoterminosService.createTermino(myJSONID).then(data => {
        if (data.err == 0) {
          notify({
            message: 'Termino ha sido agregado',
          }, 'success', 9000);
          this.loadDataTerminos();
        } else {

          notify({
            message: 'Ocurrio un error ' + data.msg,
            position: {
              my: 'center top',
              at: 'center top',
            },
          }, 'error', 9000);

        }
      });


    },
    eliminarTermino(e) {

      let myJSONID = JSON.stringify({
        pid: e.data.id
      });

      this.ordentrabajoprocedimientoterminosService.deleteTermino(myJSONID).then(data => {

        if (data.err == 0) {
          notify({
            message: 'El termino ha sido eliminado',
          }, 'success', 9000);
          this.loadDataTerminos();
        } else {

          notify({
            message: 'Ocurrio un error ' + data.msg,
            position: {
              my: 'center top',
              at: 'center top',
            },
          }, 'error', 9000);

        }

      });

    },
    editarTermino(e) {
      let myJSONID = JSON.stringify({
        pid: e.data.id,
        pnombre: e.data.nombre,
        pdescripcion: e.data.descripcion,
        pfechalimite: moment(e.data.fechalimite).format('YYYY-MM-DD'),
        pcompletado: e.data.completado,
        pobservacion: e.data.observacion,
        pevidencia_nombredocumento: e.data.evidencia_nombredocumento,
        pevidencia_ruta: e.data.evidencia_ruta
      });

      this.ordentrabajoprocedimientoterminosService.updateTermino(myJSONID).then(data => {
        if (data.err == 0) {
          notify({
            message: 'Termino ha sido actualizado',
          }, 'success', 9000);
          this.loadDataTerminos();
        } else {

          notify({
            message: 'Ocurrio un error ' + data.msg,
            position: {
              my: 'center top',
              at: 'center top',
            },
          }, 'error', 9000);

        }
      });


    },
    loadDataTerminos() {
      let myJSON = JSON.stringify({
        pid: this.vidot
      });

      this.ordentrabajoprocedimientoterminosService.getTermino(myJSON).then(data => {
        this.dataSourceTermino = data;

      });
    },
    agregarCondicion(e) {
      let idusuario = JSON.parse(Cookies.get("ets_user_ec")).id;
      let myJSONID = JSON.stringify({
        pordentrabajoid: this.vidot,
        pusuarioregistroid: idusuario,
        pnombre: e.data.nombre,
        pdescripcion: e.data.descripcion,
        pfechalimite: moment(e.data.fechalimite).format('YYYY-MM-DD'),
        pcompletado: e.data.completado,
        pobservacion: e.data.observacion,
        pevidencia_nombredocumento: e.data.evidencia_nombredocumento,
        pevidencia_ruta: e.data.evidencia_ruta
      });

      this.ordentrabajoprocedimientocondicionalesService.createCondicional(myJSONID).then(data => {
        if (data.err == 0) {
          notify({
            message: 'Condicional ha sido agregada',
          }, 'success', 9000);
          this.loadDataCondicionales();
        } else {

          notify({
            message: 'Ocurrio un error ' + data.msg,
            position: {
              my: 'center top',
              at: 'center top',
            },
          }, 'error', 9000);

        }
      });


    },
    eliminarCondicion(e) {

      let myJSONID = JSON.stringify({
        pid: e.data.id
      });

      this.ordentrabajoprocedimientocondicionalesService.deleteCondicional(myJSONID).then(data => {

        if (data.err == 0) {
          notify({
            message: 'La condicional ha sido eliminada',
          }, 'success', 9000);
          this.loadDataCondicionales();
        } else {

          notify({
            message: 'Ocurrio un error ' + data.msg,
            position: {
              my: 'center top',
              at: 'center top',
            },
          }, 'error', 9000);

        }

      });

    },

    onResourceAssigned(e) {
      //ASIGNED {taskId: 104, resourceId: 3}

      let myJSON = JSON.stringify({
        pordentrabajoid: this.vidot,
        pordentrabajoplanid: e.values.taskId,
        pclientecontactoid: e.values.resourceId,

      });


      if (e.values.taskID != 0) {
        this.ordentrabajoplanService.createOrdenTrabajoPlanRecursoTarea(myJSON).then(data => {
          if (data.err == 0) {
            this.type = 'success';
            this.message = 'Se ha actualizado el registro';
            this.isVisible = true;

            notify({
              message: this.message,
            }, this.type, 5000);
            this.popupVisible = false;
            this.loadDataInfo();
          } else {

            this.type = 'error';
            this.message = 'Ocurrio un error ' + data.msg;
            this.isVisible = true;
            notify({
              message: 'Ocurrio un error ' + data.msg,
            }, this.type, 4000);


          }
          //this.loadDataInfo();

        });
      }
    }, onResourceUnassigned(e) {

      let myJSON = JSON.stringify({
        pid: e.key,


      });

      if (e.key != 0) {
        this.ordentrabajoplanService.deleteOrdenTrabajoPlanRecursoTarea(myJSON).then(data => {
          if (data.err == 0) {
            this.type = 'success';
            this.message = 'Se ha actualizado el registro';
            this.isVisible = true;

            notify({
              message: this.message,
            }, this.type, 5000);
            this.popupVisible = false;
            this.loadDataInfo();
          } else {

            this.type = 'error';
            this.message = 'Ocurrio un error ' + data.msg;
            this.isVisible = true;
            notify({
              message: 'Ocurrio un error ' + data.msg,
            }, this.type, 4000);


          }
          //this.loadDataInfo();

        });
      }
    },
    onTaskDeleted(e) {
      let err = 0;
      if (e.key != 0) {


        let myJSON = JSON.stringify({
          pid: e.key,
          pot: this.vidot
        });





        if (e.key != 0) {

          if (err == 0) {


            this.ordentrabajoplanService.deleteOrdenTrabajoPlan(myJSON).then(data => {

              if (data.err == 0) {
                this.type = 'success';
                this.message = 'Se ha eliminado el registro';
                this.isVisible = true;

                notify({
                  message: this.message,
                }, this.type, 5000);
                this.popupVisible = false;
                this.loadDataInfo();
              } else {

                this.type = 'error';
                this.message = 'Ocurrio un error ' + data.msg;
                this.isVisible = true;
                notify({
                  message: 'Ocurrio un error ' + data.msg,
                }, this.type, 4000);


              }


            });




          } else {
            this.type = 'error';
            this.message = 'error';
            this.isVisible = true;
            notify({
              message: this.message,
            }, this.type, 4000);

          }
          e.cancel = true;
        }
      }
    },

    onTaskInserted(e) {

      let err = 0;
      let msgerror = '';

      let vfechainicio = '';
      let vfechafin = '';
      let vprogreso = '0';
      let vdescripcion = '';
      let vpadre = '';



      // Si esta indefinido no hubo cambio
      // De lo contrario si hubo cambio

      if (e.values.parentId === undefined || e.values.parentId === '' || e.values.parentId === 'null' || e.values.parentId === null) {
        vpadre = "-1";

      }
      else {
        vpadre = e.values.parentId;
      }
      vdescripcion = e.values.title;
      vfechainicio = moment(e.values.start).format('YYYY-MM-DD');
      vfechafin = moment(e.values.end).format('YYYY-MM-DD');


      // let idusuario = JSON.parse(Cookies.get("ets_user_ec")).id;//1;
      let myJSON = JSON.stringify({

        pordentrabajoid: this.vidot,
        vpadre: vpadre,
        pdescripcion: vdescripcion,
        pfechainicio: vfechainicio,
        pfechafin: vfechafin,
        pprogreso: vprogreso
      });



      if (e.key != 0) {

        if (err == 0) {
          this.ordentrabajoplanService.createOrdenTrabajoPlan(myJSON).then(data => {

            if (data.err == 0) {
              this.type = 'success';
              this.message = 'Se ha insertado el registro';
              this.isVisible = true;

              notify({
                message: this.message,
              }, this.type, 5000);
              this.popupVisible = false;
              this.loadDataInfo();
            } else {

              this.type = 'error';
              this.message = 'Ocurrio un error ' + data.msg;
              this.isVisible = true;
              notify({
                message: 'Ocurrio un error ' + data.msg,
              }, this.type, 4000);


            }
            //this.loadDataInfo();

          });
        } else {
          this.type = 'error';
          this.message = msgerror;
          this.isVisible = true;
          notify({
            message: this.message,
          }, this.type, 4000);

        }
        e.cancel = true;
      }



    },
    onTaskUpdating(e) {



      let err = 0;
      let msgerror = '';

      let vfechainicio = '';
      let vfechafin = '';
      let vprogreso = '';
      let vdescripcion = '';

      // Si esta indefinido no hubo cambio
      // De lo contrario si hubo cambio

      if (e.newValues.title === undefined || e.newValues.title === '' || e.newValues.title === 'null' || e.newValues.title === null) {
        //vfechainicio = e.values.start.getFullYear() + '-' + (e.values.start.getMonth() + 1) + '-' + e.values.start.getDate();
        vdescripcion = e.values.title;

      }
      else {
        if (e.newValues.title == undefined || e.newValues.title == null || e.newValues.title == '') {
          err = 1;
          msgerror = 'Ingresar descripción';
        }
        else {
          vdescripcion = e.newValues.title;

        }
      }


      if (e.newValues.start === undefined || e.newValues.start === '' || e.newValues.start === 'null' || e.newValues.start === null) {
        //vfechainicio = e.values.start.getFullYear() + '-' + (e.values.start.getMonth() + 1) + '-' + e.values.start.getDate();
        vfechainicio = moment(e.values.start).format('YYYY-MM-DD');

      }
      else {
        if (e.newValues.start == undefined || e.newValues.start == null || e.newValues.start == '') {
          err = 1;
          msgerror = 'Ingresar fecha inicio';
        }
        else {
          vfechainicio = moment(e.newValues.start).format('YYYY-MM-DD');

        }
      }

      if (e.newValues.end === undefined || e.newValues.end === '' || e.newValues.end === 'null' || e.newValues.end === null) {
        //vfechafin =  e.values.end.getFullYear() + '-' + (e.values.end.getMonth() + 1) + '-' + e.values.end.getDate();
        vfechafin = moment(e.values.end).format('YYYY-MM-DD');
      }
      else {
        if (e.newValues.end == undefined || e.newValues.end == null || e.newValues.end == '') {
          err = 1;
          msgerror = 'Ingresar fecha fin';
        }
        else {
          //vfechafin = e.newValues.end.getFullYear() + '-' + (e.newValues.end.getMonth() + 1) + '-' + e.newValues.end.getDate();
          vfechafin = moment(e.newValues.end).format('YYYY-MM-DD');
        }
      }

      if (e.newValues.progress === undefined || e.newValues.progress === '' || e.newValues.progress === 'null' || e.newValues.progress === null) {


        vprogreso = e.values.progress;
      }
      else {
        if ( e.newValues.progress == 0){
          vprogreso = e.newValues.progress;
        } else if (e.newValues.progress === undefined || e.newValues.progress == null || e.newValues.progress == '') {
          err = 1;
          msgerror = 'Ingresar progreso';
        }
        else {

          vprogreso = e.newValues.progress;
        }
      }



      // let idusuario = JSON.parse(Cookies.get("ets_user_ec")).id;//1;
      let myJSON = JSON.stringify({
        pid: e.key,
        pordentrabajoid: this.vidot,
        pdescripcion: vdescripcion,
        pfechainicio: vfechainicio,
        pfechafin: vfechafin,
        pprogreso: vprogreso
      });



      if (e.key != 0) {

        if (err == 0) {
          this.ordentrabajoplanService.updateOrdenTrabajoPlan(myJSON).then(data => {

            if (data.err == 0) {
              this.type = 'success';
              this.message = 'Se ha actualizado el registro';
              this.isVisible = true;

              notify({
                message: this.message,
              }, this.type, 5000);
              this.popupVisible = false;
              this.loadDataInfo();
            } else {

              this.type = 'error';
              this.message = 'Ocurrio un error ' + data.msg;
              this.isVisible = true;
              notify({
                message: 'Ocurrio un error ' + data.msg,
              }, this.type, 4000);


            }
            //this.loadDataInfo();

          });
        } else {
          this.type = 'error';
          this.message = msgerror;
          this.isVisible = true;
          notify({
            message: this.message,
          }, this.type, 4000);

        }
        e.cancel = true;
      }
    },



    editarCondicion(e) {
      let myJSONID = JSON.stringify({
        pid: e.data.id,
        pnombre: e.data.nombre,
        pdescripcion: e.data.descripcion,
        pfechalimite: moment(e.data.fechalimite).format('YYYY-MM-DD'),
        pcompletado: e.data.completado,
        pobservacion: e.data.observacion,
        pevidencia_nombredocumento: e.data.evidencia_nombredocumento,
        pevidencia_ruta: e.data.evidencia_ruta
      });

      this.ordentrabajoprocedimientocondicionalesService.updateCondicional(myJSONID).then(data => {
        if (data.err == 0) {
          notify({
            message: 'Condicional ha sido actualizada',
          }, 'success', 9000);
          this.loadDataCondicionales();
        } else {

          notify({
            message: 'Ocurrio un error ' + data.msg,
            position: {
              my: 'center top',
              at: 'center top',
            },
          }, 'error', 9000);

        }
      });


    },
    loadDataCondicionales() {
      let myJSON = JSON.stringify({
        pid: this.vidot
      });

      this.ordentrabajoprocedimientocondicionalesService.getCondicional(myJSON).then(data => {
        this.dataSourceCondicion = data;

      });
    },
    agregarPrograma(e) {
      let idusuario = JSON.parse(Cookies.get("ets_user_ec")).id;
      let myJSONID = JSON.stringify({
        pordentrabajoid: this.vidot,
        pusuarioregistroid: idusuario,
        pnombre: e.data.nombre,
        pdescripcion: e.data.descripcion,
        pfechalimite: moment(e.data.fechalimite).format('YYYY-MM-DD'),
        pcompletado: e.data.completado,
        pobservacion: e.data.observacion,
        pevidencia_nombredocumento: e.data.evidencia_nombredocumento,
        pevidencia_ruta: e.data.evidencia_ruta
      });

      this.ordentrabajoprocedimientoprogramasService.createPrograma(myJSONID).then(data => {
        if (data.err == 0) {
          notify({
            message: 'Programa ha sido agregado',
          }, 'success', 9000);
          this.loadDataProgramas();
        } else {

          notify({
            message: 'Ocurrio un error ' + data.msg,
            position: {
              my: 'center top',
              at: 'center top',
            },
          }, 'error', 9000);

        }
      });


    },
    eliminarPrograma(e) {

      let myJSONID = JSON.stringify({
        pid: e.data.id
      });

      this.ordentrabajoprocedimientoprogramasService.deletePrograma(myJSONID).then(data => {

        if (data.err == 0) {
          notify({
            message: 'El termino ha sido eliminado',
          }, 'success', 9000);
          this.loadDataProgramas();
        } else {

          notify({
            message: 'Ocurrio un error ' + data.msg,
            position: {
              my: 'center top',
              at: 'center top',
            },
          }, 'error', 9000);

        }

      });

    },
    editarPrograma(e) {
      let myJSONID = JSON.stringify({
        pid: e.data.id,
        pnombre: e.data.nombre,
        pdescripcion: e.data.descripcion,
        pfechalimite: moment(e.data.fechalimite).format('YYYY-MM-DD'),
        pcompletado: e.data.completado,
        pobservacion: e.data.observacion,
        pevidencia_nombredocumento: e.data.evidencia_nombredocumento,
        pevidencia_ruta: e.data.evidencia_ruta
      });

      this.ordentrabajoprocedimientoprogramasService.updatePrograma(myJSONID).then(data => {
        if (data.err == 0) {
          notify({
            message: 'Termino ha sido actualizado',
          }, 'success', 9000);
          this.loadDataProgramas();
        } else {

          notify({
            message: 'Ocurrio un error ' + data.msg,
            position: {
              my: 'center top',
              at: 'center top',
            },
          }, 'error', 9000);

        }
      });


    },
    loadDataProgramas() {
      let myJSON = JSON.stringify({
        pid: this.vidot
      });

      this.ordentrabajoprocedimientoprogramasService.getPrograma(myJSON).then(data => {
        this.dataSourcePrograma = data;

      });
    },
    loadDataInfo() {
      this.responsabilidadentregaService.getResponsabilidadEntrega().then(data => this.dataSourceResponsabilidadEntrega = data);
     
       //Informacion Complementaria OT
      let myJSON_Com = JSON.stringify({
        pordentrabajoid: this.vidot,
      });
      this.ordentrabajoresultadoService.getResolutivo(myJSON_Com).then(data => {
        
        this.dataSourceResolutivo = data
      });
      this.ordentrabajoresultadoService.getComplementaria(myJSON_Com).then(data => {
        this.dataSourceComplementaria = data
      });
      this.ordentrabajoresultadoService.getVigencia().then(data => {
        this.dataSourceVigencia = data
      });


      this.ordentrabajoresultadoService.getResponsable(myJSON_Com).then(data => {
        console.log('datos respo',data);
        this.dataSourceResponsable = data
      });
       
    
      


      let myJSON2 = JSON.stringify({
        pid: this.vidot,
      });

      this.ordentrabajoresultadoService.getOrdenTrabajoResultadoPorId(myJSON2).then(data => {
        this.customer = data
        this.pdfRazon = data.razonsocial;
        this.pdfFechaInicio = data.fechainicioplaneada;
        this.est_complementaria = data.informacioncomplementaria,
        this.est_resolutivo = data.informacionemision;
        this.vidotresponsable = data.responsable;
      }

      );
      this.ordentrabajoresultadoService.getOrdenTrabajoResultadoPorId2(myJSON2).then(data => {
         
        this.dataSourceCambioEstatus = data
      }

      );



      this.ordentrabajoresultadoService.getOrdenTrabajoResultadoPorId2(myJSON2).then(data => {
        this.dataSourceEtapaCierre = data;
      }

      );


      this.tipodocumentoService.getTipoDocumento().then(data => {
        this.dataSourceDocumento = data;

      });



      //this.usuarioService.getUsuario().then(data => this.dataSourceUsuarios = data);
      let id = this.vidot;

      let myJSON = JSON.stringify({
        pid: id,

      });


      this.ordentrabajoresultadoService.getOrdenTrabajoHistorialCambios(myJSON).then(data => {
        this.dataSourceHistorial = data;
      });
      this.ordentrabajodocumentoService.getOrdenTrabajoDocumento(myJSON).then(data => {
        this.dataSourceDocumentacion = data;
      });



      this.ordentrabajoresultadoService.getOrdenTrabajoResultadoListadoDocumentacion(myJSON).then(data => {
        data.forEach(
          element => {

            if (element.entregado == 1) {
              element.entregado = true;
            } else {
              element.entregado = false;
            }
            if (element.aplica == 1) {
              element.aplica = true;
            } else {
              element.aplica = false;
            }

          });
        this.dataSourceListados = data;

      });


      this.ordentrabajoresultadoService.getOrdenTrabajoResultadoListadoInformacion(myJSON).then(data => {
        data.forEach(
          element => {

            if (element.entregado == 1) {
              element.entregado = true;
            } else {
              element.entregado = false;
            }
            if (element.aplica == 1) {
              element.aplica = true;
            } else {
              element.aplica = false;
            }

          });
        this.dataSourceEntrega = data;

      });



      this.ordentrabajoetapaService.getOrdenTrabajoEtapaConsulta(myJSON).then(data => {
        this.dataSourceEtapa = data;
      }
      );

      this.estatusService.getGrupoEstatusOrden(myJSON).then(data => {
        this.dataSourceEstatus = data;
      }
      );
       
      /**
      this.ordentrabajoprocedimientomedidasService.getTipoMedida().then(data => {
        this.dataSourceTipoMedida = data;
      });

      this.ordentrabajoprocedimientomedidasService.getFactorAmbiental().then(data => {
        this.dataSourceFactorAmbiental = data;
      });

      this.ordentrabajoprocedimientomedidasService.getAccion(myJSON).then(data => {
        this.dataSourceAccion = data;
      });

      this.ordentrabajoprocedimientomedidasService.getMedidaSeguimiento(myJSON).then(data => {
        this.dataSourceMedidasSeguimiento = data;

      })

      this.ordentrabajoprocedimientoterminosService.getTermino(myJSON).then(data => {
        this.dataSourceTermino = data;

      });

      this.ordentrabajoprocedimientocondicionalesService.getCondicional(myJSON).then(data => {
        this.dataSourceCondicion = data;

      });

      this.ordentrabajoprocedimientoprogramasService.getPrograma(myJSON).then(data => {
        this.dataSourcePrograma = data;

      });
      **/

      // GANTT

      this.ordentrabajoplanService.getOrdenTrabajoPlanTareas(myJSON).then(data => {
        this.obtenerFechaMasAntigua(data);
        this.tasks = data;
        
      }
      );

      this.ordentrabajoplanService.getOrdenTrabajoPlanDependencias(myJSON).then(data => {
        this.dependencies = data
      }
      );


      this.ordentrabajoplanService.getOrdenTrabajoPlanContactoCliente(myJSON).then(data => { this.resources = data });


      this.ordentrabajoplanService.getOrdenTrabajoPlanRecursoTarea(myJSON).then(data => {
        this.resourceAssignments = data
      }
      );

      

      // FIN GANTT

    },
    obtenerFechaMasAntigua(value) {
      let fechaAntigua = null;
      let fechaFinal = null;
      for (let objeto of value) {
        const fechaObjetoInicio = new Date(objeto.start);
        const fechaObjetoFinal = new Date(objeto.end);

        if (!fechaAntigua || fechaObjetoInicio < fechaAntigua) {
          fechaAntigua = fechaObjetoInicio;
        }

        if (!fechaFinal || fechaObjetoFinal > fechaFinal) {
          fechaFinal = fechaObjetoFinal;
        }
      }

      this.fechaInicio = fechaAntigua;
      this.fechaFinal = fechaFinal;
      this.fechaActual = new Date();

    },
    passwordComparison() {
      return this.customer.Password;
    },
    checkComparison() {
      return true;
    },
    asyncValidation(params) {
      return sendRequest(params.value);
    },
    handleSubmit(e) {




      let myJSON = JSON.stringify({
        pid: this.customer.ID,
        pnombre: this.customer.Nombre,
        ppaterno: this.customer.Paterno,
        pmaterno: this.customer.Materno,
        pusuario: this.customer.Usuario,
        pcontrasena: this.customer.Password,
        pcorreo: this.customer.Email,
      });


      this.usuarioService.updateUsuarioPerfil(myJSON).then(data => {

        if (data.err == 0) {
          Cookies.set("ets_user_ec", "null");
          let myJSON = JSON.stringify({
            id: this.customer.ID,
            nombre: this.customer.Nombre + ' ' + this.customer.Paterno + ' ' + this.customer.Materno,
            correo: this.customer.Email,
          });
          Cookies.set("ets_user_ec", myJSON);
          auth.logIn(this.customer.Nombre + ' ' + this.customer.Paterno + ' ' + this.customer.Materno);
          notify({
            message: 'Perfil ha sido actualizado',
          }, 'success', 9000);
          document.location.reload();
        } else {

          notify({
            message: 'Ocurrio un error ' + data.msg,
            position: {
              my: 'center top',
              at: 'center top',
            },
          }, 'error', 9000);
          document.location.reload();

        }


      });

      e.preventDefault();
    },
    downloadfile(e) {
      let name_file = e.row.data.ruta;


      axios({
        url: process.env.VUE_APP_APIURL + '/api/archivodownload?name_file=' + name_file,
        method: 'GET',
        responseType: 'blob',

      }).then((res) => {
        var url = window.URL.createObjectURL((res.data));
        var namepdf = name_file;
        //var cliente = "";
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', namepdf);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });



    },
   
    editProcedureListadoDocumentacion(e) {
       
      let ventregado = e.data.entregado == true ? 1 : 0;
      let vaplica = e.data.aplica == true ? 1 : 0;
      let vresponsabilidadentregaid = e.data.responsabilidadentregaid;
      let myJSON;
      if(e.data.aplica==true || e.data.aplica=='true'){
        myJSON = JSON.stringify({
          pid: e.data.id,
          pentregado: null,
          pfechaentrega: null,
          paplica: vaplica,
          presponsabilidadentregaid : vresponsabilidadentregaid
        });
      } else {
        myJSON = JSON.stringify({
          pid: e.data.id,
          pentregado: ventregado,
          pfechaentrega: moment(e.data.fechaentrega).format('YYYY-MM-DD'),
          paplica: vaplica,
          presponsabilidadentregaid : vresponsabilidadentregaid
        });
      }
      console.log('CONSO listado documen...............',myJSON);
      this.esCampoRequerido2 = true;
      
      this.ordentrabajoresultadoService.updateOrdenTrabajoListadoDocumentacion(myJSON).then(data => {

        if (data.err == 0) {
          this.type = 'success';
          this.message = 'Se ha actualizado el registro';
          this.isVisible = true;

          notify({
            message: this.message,
          }, this.type, 9000);
          this.loadDataInfo();
        } else {

          this.type = 'error';
          this.message = 'Ocurrio un error ' + data.msg;
          this.isVisible = true;
          notify({
            message: 'Ocurrio un error ' + data.msg,
            position: {
              my: 'center top',
              at: 'center top',
            },
          }, 'error', 9000);
        }
        this.loadDataInfo();

      });

    },
    editorPreparingListados(e){
      if (e.dataField === 'aplica' && e.parentType === 'dataRow') {
        
        e.editorOptions.onValueChanged = (innerEvent) => {
          //this.$forceUpdate();
          // innerEvent.value contiene el nuevo valor del checkbox
          let valor = innerEvent.value;
          this.esCampoRequerido = !valor;
          e.setValue(valor);
         
        };
      }
    },
    editorPreparingDocumentos(e){
      if (e.dataField === 'aplica' && e.parentType === 'dataRow') {
        
        e.editorOptions.onValueChanged = (innerEvent) => {
          //this.$forceUpdate();
          // innerEvent.value contiene el nuevo valor del checkbox
          let valor = innerEvent.value;
          this.esCampoRequerido2 = !valor;
          e.setValue(valor);
         
        };
      }
    },
    cambioEscala(e){
      this.tipoEscala = e.value;
    },
    cambioResponsable(e){
      

   
                
      
      let myJSON;
      myJSON = JSON.stringify({
          pordentrabajoid: this.vidot,
          pid: 0,
          ptiporesponsable: e.value
        });
        
        this.ordentrabajoresultadoService.cambioResponsable(myJSON).then(data => {
      
      
        
        if (data.err == 0) {
          notify({
            message: 'Registro ha sido actualizado',
          }, 'success', 9000);
         
        } else {

          notify({
            message: 'Ocurrio un error ' + data.msg,
            position: {
              my: 'center top',
              at: 'center top',
            },
          }, 'error', 9000);

        }
      });
       
    },
    onResourceManagerDialogShowing(e){
      e.cancel = true;
    },
    onInitNewRow(e) {
      e.data.entregado = true;
    },
  },
};
