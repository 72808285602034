


import DxForm, {
  DxGroupItem,
  DxSimpleItem,
  DxLabel,
  DxItem
} from 'devextreme-vue/form';


import {
  DxDataGrid,
  DxEditing,
  DxColumn,
  DxPaging,
  DxFilterRow,
  DxSearchPanel,
  DxScrolling,
  DxPager,
  DxGrouping,
  DxGroupPanel,
  DxFormItem,
  DxValidationRule,
  DxColumnChooser,
  DxLookup,
  DxButton,
  DxMasterDetail,
} from 'devextreme-vue/data-grid';

import {
  DxGantt,
  DxTasks,
  DxDependencies,
  DxResources,
  DxResourceAssignments,
  DxValidation,
  DxToolbar,
  DxHeaderFilter,
  DxStripLine,
  DxContextMenu,

} from 'devextreme-vue/gantt';
import DxDateBox from 'devextreme-vue/date-box';
import axios from 'axios';
import DxCheckBox from 'devextreme-vue/check-box';
import DxPopup, { DxToolbarItem } from 'devextreme-vue/popup';
import DxScrollView from 'devextreme-vue/scroll-view';
import { DxSelectBox } from 'devextreme-vue/select-box';
import DxTabPanel from "devextreme-vue/tab-panel";
import { customers } from './../data/dataperfil.js';
import { DxToast } from 'devextreme-vue/toast';
import 'devextreme-vue/text-area';
import notify from 'devextreme/ui/notify';
import moment from 'moment';
import Cookies from "js-cookie";
import auth from "../auth";
window.Cookies = Cookies;

import ListadoInformacionService from '../services/listadoinformacion-service';
import ListadoDocumentacionService from '../services/listadodocumentacion-service';
import OrdenTrabajoResultadoService from '../services/ordentrabajoresultado-service';
import OrdenTrabajoDocumentoService from '../services/ordentrabajodocumento-service';
import OrdenTrabajoEtapaService from '../services/ordentrabajoetapa-service';
import OrdenTrabajoPlanService from '../services/ordentrabajoplan-service';
import TipoDocumentoService from '../services/tipodocumento-service';
import EstatusService from '../services/estatus-service';
import DxTextArea from 'devextreme-vue/text-area';
 
import { DxTextBox } from 'devextreme-vue/text-box';
import { confirm } from 'devextreme/ui/dialog';
import ResponsabilidadEntregaService from '../services/responsabilidadentrega-service';
import GuiaEvaluacionEstandarFundamentoService from '../services/guiaevaluacionestandarfundamento-service';
import LeyService from '../services/ley-service';
import DetailEcaTemplate from './ecaevidencia.vue';
import { DxFileUploader } from 'devextreme-vue/file-uploader';

import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver-es';
import { exportGantt as exportGanttToPdf } from 'devextreme/pdf_exporter';
const sendRequest = function (value) {
  const validEmail = 'test@dx-email.com';
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(value === validEmail);
    }, 1000);
  });
};
const ganttRef = 'gantt';
export default {
  components: {
    DxPopup,
    DxToolbarItem,
    DxScrollView,
    DxColumnChooser,
    DxGroupItem,
    DxSimpleItem,
    DxDataGrid,
    DxTabPanel,
    DxItem,
    DxToast,
    DxEditing,
    DxColumn,
    DxPaging,
    DxForm,
    DxFilterRow,
    DxSearchPanel,
    DxScrolling,
    DxPager,
    DxLabel,
    DxGroupPanel,
    DxGrouping,
    DxFormItem,
    DxSelectBox,
    DxButton,
    DxTextArea,
    DxFileUploader,


    DxTextBox,
    DxValidationRule,
    DxGantt,
    DxTasks,
    DxDependencies,
    DxResources,
    DxResourceAssignments,
    DxCheckBox,
    DxLookup,
    DxValidation,
    DxToolbar,
    DxHeaderFilter,
    DxStripLine,
    DxContextMenu,
    DxDateBox,
    DxMasterDetail,
    DetailEcaTemplate
  },
  data() {
    return {
     

      sumamin:null,
      sumamed:null,
      sumamax:null,
      datosSuma:null,

      dataSourceResponsable: null,
      dataSourceResponsableUsuario: null,
      popupOptions_edicion_resultado: 'Edición Responsable',

      popupTitleFundamento: "Vinculación Fundamento",
      frm_ed_estandar: null,
      frm_ed_id: null,
      dataSourceLey: null,
      leyService: null,
      popupVisibleFundamento: false,
      dataSourceFundamentoEstandar: null,
      dataSourceResponsabilidadEntrega: null,
      ganttRef,
      eca_id: null,
      viewPDF: null,
      eca_materia: null,
      eca_recomendacion: null,
      eca_observacion: null,
      eca_pregunta: null,
      eca_si: false,
      eca_no: false,
      eca_parcial: false,
      eca_noaplica: false,
      eca_aplicagantt: false,
      eca_fundamentos: null,
      popupVisible: false,
      popupTitle: "Resultados ECA",
      loading: true,
      tasks: null,
      taskTitlePosition: 'none',
      dependencies: null,
      resources: null,
      resourceAssignments: null,

      fechaInicio: null,
      fechaFinal: null,
      fechaActual: null,
      tipoEscala: "weeks",
      taskColors: null,
      firstTaskStartDate: null,

      contextMenuItems: [
        'addTask',
        'taskdetails',
        'deleteTask',
      ],

      datosEscala: [
        { nombre: "Horas", campo: "hours" },
        { nombre: "Dias", campo: "days" },
        { nombre: "Semanas", campo: "weeks" },
        { nombre: "Meses", campo: "months" },
        { nombre: "Años", campo: "years" },
      ],

      exportXLSXButtonOptions: {
        hint: 'Exportar como Excel',
        icon: 'exportxlsx',
        stylingMode: 'text',
        onClick: () => {
          this.exportGanttExcel();
        },
      },

      pdfRazon: null,
      pdfFechaInicio: null,

      // Carga documentos listados
      popupVisibleCargaLI:false,
      frm_cargali:null,
      frm_cargalidescripcion:null,
      popupTitleCarga: "Vinculación Archivo",
      dataSourceDocumentoLI:null,
      frm_cargapermite:'N',
      popupVisibleCargaLD:false,
      frm_cargald:null,
      frm_cargalddescripcion:null,
      dataSourceDocumentoLD:null,

      dataSource: customers,
      dataSourceEstatus: null,
      dataSourceCambioEstatus: null,
      dataSourceEtapaCierre: null,
      dataSourceEstandar: null,
      dataSourceResultadosNO: null,
      dataSourceResultadosConRecomendacion: null,
      dataSourceResultadosECAFormato: null,
      dataSourceEntrega: null,
      dataSourceListados: null,
      dataSourceDocumentacion: null,
      dataSourceEtapa: null,
      dataSourceECA: null,
      dataSourceHistorial: null,
      dataSourceDocumento: null,
      datosMultas: [],


      li_popupVisible: false,
      ld_popupVisible: false,
      v_li_id: null,
      v_li_aplica: null,
      v_li_entregado: null,
      v_li_recibio: null,
      v_li_fechaentrega: null,
      v_li_fechacompromisoentrega: null,
      v_li_responsabilidadentregaid: null,
      c_li_responsabilidadentregaid: null,
      v_li_observaciones: null,
      v_li_descripcion: null,

      v_ld_id: null,
      v_ld_entregado: null,
      v_ld_fechaentrega: null,
      v_ld_aplica: null,
      v_ld_responsabilidadentregaid: null,
      c_ld_responsabilidadentregaid: null,
      v_ld_descripcion: null,
      popup_ld_OptionsListado: 'Edición Resultados - Documentación',

      popup_li_OptionsListado: 'Edición Resultados - Información',

      v_pop_multa: null,
     dataSourceMultasConsulta: null,
     popupMultas: 'Listado de Multas',
     multas_popupVisible: false,

      datosPDFResultados: null,

      showInfo: true,
      showNavButtons: true,
      isVisible: false,
      message: '',
      type: 'info',
      esCampoRequerido: true,
      esCampoRequerido2: true,
      popupOptionsListado: {
        title: 'Resultados',
        showTitle: true,
        width: 650,
        height: 400,
      },
      popupOptions: {
        title: 'Resultados',
        showTitle: true,
        width: 650,
        height: 750,
      }, popupOptions2: {
        title: 'Resultados',
        showTitle: true,
        width: 400,
        height: 370,
      },
      popupOptions3: {
        title: 'Cambio Etapa',
        showTitle: true,
        width: 300,
        height: 200,
      },
      popupOptionsEstatus: {
        title: 'Estatus',
        showTitle: true,
        width: 300,
        height: 200,
      },
      popupOptions4: {
        title: 'Estandar - Fundamento',
        showTitle: true,
        width: 600,
        height: 560,
      },
      valetapa: 0,
      valcierre: '',
      valtipodocumento: 0,
      valnombredocumento: '',
      columns: ['CompanyName', 'City', 'State', 'Phone', 'Fax'],
      customer: null,
      buttonOptions: {
        text: 'Actualizar Orden Trabajo',
        type: 'success',
        useSubmitBehavior: true,
      },
      readonlyOptions: {
        disabled: true,
      },
      passwordOptions: {
        mode: 'password',
      },
      dateBoxOptions: {
        invalidDateMessage:
          'Campos tipo fecha debe tener el siguiente formato: MM/dd/yyyy',
      },
      checkBoxOptions: {
        text: 'I agree to the Terms and Conditions',
        value: false,
      },
      phoneEditorOptions: {
        mask: '+1 (X00) 000-0000',
        maskRules: {
          X: /[02-9]/,
        },
        maskInvalidMessage: 'The phone must have a correct USA phone format',
      },
      currencyOptions: {
        mask: '#,##0.00',
        maskInvalidMessage: 'The phone must have a correct USA phone format',

      },

      maxDate: new Date().setFullYear(new Date().getFullYear() - 21),
      namePattern: /^[^0-9]+$/,
      cityPattern: /^[^0-9]+$/,
      phonePattern: /^[02-9]\d{9}$/,
      numsPattern: /[0-9]/,
      vidot: null,
      vidotresponsable: null,
      backendURL: process.env.VUE_APP_APIURL,
      exportButtonOptions: {
        hint: 'Export to PDF',
        icon: 'exportpdf',
        stylingMode: 'text',
        onClick: () => {
          this.exportGantt();
        },
      },
    };
  },
  computed: {
    gantt() {
      return this.$refs[ganttRef].instance;
    },
  },
  created() {
    this.ListadoInformacionService = new ListadoInformacionService();
    this.ListadoDocumentacionService = new ListadoDocumentacionService();
    this.GuiaEvaluacionEstandarFundamentoService = new GuiaEvaluacionEstandarFundamentoService();
    this.leyService = new LeyService();
    this.ordentrabajoresultadoService = new OrdenTrabajoResultadoService();
    this.ordentrabajoetapaService = new OrdenTrabajoEtapaService();
    this.ordentrabajodocumentoService = new OrdenTrabajoDocumentoService();
    this.ordentrabajoplanService = new OrdenTrabajoPlanService();
    this.tipodocumentoService = new TipoDocumentoService();
    this.estatusService = new EstatusService();
    this.responsabilidadentregaService = new ResponsabilidadEntregaService();
    this.vidot = this.$route.params.id;

  },
  mounted() {
    this.loadDataInfo();
  },
  methods: {
    handleValueChange_li_aplica(e) {
      if (e.value == true) {
        this.v_li_aplica = false;
      }

    },
    handleValueChange_li_noaplica(e) {


      if (e.value == true) {
        this.v_li_entregado = false;

        this.v_li_recibio = '';
        this.v_li_fechaentrega = '';
        this.v_li_fechacompromisoentrega = '';
        this.v_li_observaciones = '';
      }


    },
    handleValueChange_ld_aplica(e) {
      if (e.value == true) {
        this.v_ld_aplica = false;
      }

    },
    handleValueChange_ld_noaplica(e) {


      if (e.value == true) {
        this.v_ld_entregado = false;


        this.v_ld_fechaentrega = '';

      }


    },

    editListadoInformacion(e) {

      this.v_li_id = e.row.data.id;
      this.v_li_descripcion = e.row.data.descripcion;
      this.v_li_aplica = e.row.data.aplica;
      this.v_li_entregado = e.row.data.entregado;
      this.v_li_recibio = e.row.data.recibio;
      this.v_li_fechaentrega = e.row.data.fechaentrega;
      this.v_li_fechacompromisoentrega = e.row.data.fechacompromisoentrega;
      this.v_li_responsabilidadentregaid = e.row.data.responsabilidadentregaid;
      this.c_li_responsabilidadentregaid = e.row.data.responsabilidadentregaid;
      this.v_li_observaciones = e.row.data.observaciones;

      this.li_popupVisible = true;

    },
    saveListadoInformacion() {
      let err = 0;
      let msgerror = '';
      let vid = this.v_li_id;
      let vresponsabilidadentregaid = (this.v_li_responsabilidadentregaid == '') ? 1 : this.v_li_responsabilidadentregaid;
      let vrecibio = (this.v_li_recibio == '') ? '' : this.v_li_recibio;
      let vfechaentrega = (this.v_li_fechaentrega == '') ? '9999-01-01' : moment(this.v_li_fechaentrega).format('YYYY-MM-DD');
      let vfechacompromisoentrega = (this.v_li_fechacompromisoentrega == '') ? '9999-01-01' : moment(this.v_li_fechacompromisoentrega).format('YYYY-MM-DD');
      let vobservaciones = (this.v_li_observaciones == '') ? '' : this.v_li_observaciones;
      let ventregado = this.v_li_entregado == true ? 1 : 0;
      let vaplica = this.v_li_aplica == true ? 1 : 0;
      let idusuario = JSON.parse(Cookies.get("ets_user")).id;//1;
      let myJSON;

      if (vaplica == 0 && ventregado == 0) {
        if (this.c_li_responsabilidadentregaid === vresponsabilidadentregaid) {
          err = 1;
          msgerror = 'Seleccione No Aplica o Entrega';
          this.type = 'error';
          this.message = msgerror;
          this.isVisible = true;

          notify({
            message: this.message,
          }, this.type, 9000);
        }
      }



      if (vaplica == 1 || vaplica == '1') {
        myJSON = JSON.stringify({
          pid: vid,
          pentregado: 0,
          pfechaentrega: '9999-01-01',
          pfechacompromisoentrega: '9999-01-01',
          pusuarioregistroid: idusuario,
          pobservaciones: '',
          precibio: '',
          paplica: vaplica,
          presponsabilidadentregaid: vresponsabilidadentregaid
        });
      } else {
        myJSON = JSON.stringify({
          pid: vid,
          pentregado: ventregado,
          pfechaentrega: vfechaentrega,
          pfechacompromisoentrega: vfechacompromisoentrega,
          pusuarioregistroid: idusuario,
          pobservaciones: vobservaciones,
          precibio: vrecibio,
          paplica: vaplica,
          presponsabilidadentregaid: vresponsabilidadentregaid
        });
      }

      console.log('JSON', myJSON);




      if (err == 0) {
        this.ordentrabajoresultadoService.updateOrdenTrabajoListadoInformacion(myJSON).then(data => {
          if (data.err == 0) {

            this.type = 'success';
            this.message = 'Se ha actualizado el registro';
            this.isVisible = true;

            notify({
              message: this.message,
            }, this.type, 9000);
            this.li_popupVisible = false;
            this.loadDataInfo();

          } else {

            this.type = 'error';
            this.message = 'Ocurrio un error ' + data.msg;
            this.isVisible = true;
            notify({
              message: 'Ocurrio un error ' + data.msg,
              position: {
                my: 'center top',
                at: 'center top',
              },
            }, 'error', 9000);
          }
          //this.loadDataInfo();

        });


      }





    },

    editListadoDocumentacion(e) {

      this.v_ld_id = e.row.data.id;
      this.v_ld_descripcion = e.row.data.descripcion;
      this.v_ld_aplica = e.row.data.aplica;
      this.v_ld_entregado = e.row.data.entregado;
      this.v_ld_fechaentrega = e.row.data.fechaentrega;
      this.v_ld_responsabilidadentregaid = e.row.data.responsabilidadentregaid;
      this.c_ld_responsabilidadentregaid = e.row.data.responsabilidadentregaid;
      this.ld_popupVisible = true;

    },

    saveListadoDocumentacion() {
      let err = 0;
      let msgerror = '';
      let vid = this.v_ld_id;
      let vresponsabilidadentregaid = (this.v_ld_responsabilidadentregaid == '') ? 1 : this.v_ld_responsabilidadentregaid;
      let vfechaentrega = (this.v_ld_fechaentrega == '') ? '9999-01-01' : moment(this.v_ld_fechaentrega).format('YYYY-MM-DD');
      let ventregado = this.v_ld_entregado == true ? 1 : 0;
      let vaplica = this.v_ld_aplica == true ? 1 : 0;
      //let idusuario = JSON.parse(Cookies.get("ets_user")).id;//1;
      let myJSON;

      if (vaplica == 0 && ventregado == 0) {
        if (this.c_ld_responsabilidadentregaid === vresponsabilidadentregaid) {
          err = 1;
          msgerror = 'Seleccione No Aplica o Entrega';
          this.type = 'error';
          this.message = msgerror;
          this.isVisible = true;

          notify({
            message: this.message,
          }, this.type, 9000);
        }
      }



      if (vaplica == 1 || vaplica == '1') {
        myJSON = JSON.stringify({
          pid: vid,
          pentregado: 0,
          pfechaentrega: '9999-01-01',
          paplica: vaplica,
          presponsabilidadentregaid: vresponsabilidadentregaid

        });
      } else {
        myJSON = JSON.stringify({
          pid: vid,
          pentregado: ventregado,
          pfechaentrega: vfechaentrega,
          paplica: 0,
          presponsabilidadentregaid: vresponsabilidadentregaid
        });
      }



      if (err == 0) {
        this.ordentrabajoresultadoService.updateOrdenTrabajoListadoDocumentacion(myJSON).then(data => {

          if (data.err == 0) {
            this.type = 'success';
            this.message = 'Se ha actualizado el registro';
            this.isVisible = true;

            notify({
              message: this.message,
            }, this.type, 9000);
            this.ld_popupVisible = false;
            this.loadDataInfo();
          } else {

            this.type = 'error';
            this.message = 'Ocurrio un error ' + data.msg;
            this.isVisible = true;
            notify({
              message: 'Ocurrio un error ' + data.msg,
              position: {
                my: 'center top',
                at: 'center top',
              },
            }, 'error', 9000);
          }
          this.loadDataInfo();

        });


      }





    },
    async exportGanttExcel() {

      //Obtenemos los datos de las tareas
      var ganttData = this.gantt._tasks;

      //Creamos nuestro workbook y worksheet
      var workbook = new ExcelJS.Workbook();
      var worksheet = workbook.addWorksheet("GanttData");


      var excelData = ganttData.map((task) => {
        var formattedStartDate = this.formatoFecha(task.start);
        var formattedEndDate = this.formatoFecha(task.end);

        return [
          task.title,
          formattedStartDate,
          formattedEndDate,
          // Add more fields as needed
        ];
      });

      var realData = ganttData.map((task) => {
        return [
          task.title,
          task.start,
          task.end,
        ];
      });

      this.taskColors = this.generateTaskColors(realData.length);

      // Add columns for months and apply styling to the timeline
      this.addMonthColumnsExcelJS(worksheet, realData);

      worksheet.addTable({
        name: 'GanttDataTable',
        ref: 'A8:C' + (ganttData.length + 1), // Assuming data starts from row 1
        headerRow: true,
        totalsRow: false,
        style: {
          theme: 'TableStyleLight1', // Choose a theme as needed
          showRowStripes: true,
        },
        columns: [
          { name: 'Tarea', filterButton: true, width: 80 },
          { name: 'Fecha Inicio', filterButton: true, width: 40 },
          { name: 'Fecha Final', filterButton: true, width: 40 },
          // Add more columns as needed
        ],
        rows: excelData, // Add row definitions
      });


      // Set the bold style for each header cell individually
      worksheet.getRow(8).eachCell({ includeEmpty: true }, (cell) => {
        cell.style = {
          font: { bold: true },
          fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFD3D3D3' } },
          alignment: { horizontal: 'center' }
        };
      });
      worksheet.getColumn(1).width = 50;
      worksheet.getColumn(2).width = 15;
      worksheet.getColumn(3).width = 15;

      worksheet.mergeCells('B2:I2');
      const cellB2 = worksheet.getCell('B2');
      cellB2.value = 'LUHOTT-MERAKI ASESORES AMBIENTALES, S.A. DE C.V.';
      cellB2.font = { bold: true };
      cellB2.alignment = { horizontal: 'center', vertical: 'middle' };
      worksheet.mergeCells('B3:I3');
      const cellB3 = worksheet.getCell('B3');
      cellB3.value = 'FRAY LUIS DE LEÓN 1730, COL. JARDÍN ESPAÑOL, MONTERREY, N.L., C.P. 64820';
      cellB3.alignment = { horizontal: 'center', vertical: 'middle' };
      worksheet.mergeCells('B4:I4');
      const cellB4 = worksheet.getCell('B4');
      cellB4.value = 'TEL. 81 8387 4515 E-MAIL: info@lmeraki.com';
      cellB4.alignment = { horizontal: 'center', vertical: 'middle' };
      worksheet.mergeCells('B5:I5');
      const cellB5 = worksheet.getCell('B5');
      cellB5.value = 'www.lmeraki.com';
      cellB5.alignment = { horizontal: 'center', vertical: 'middle' };

      var imageURL = require('@/assets/logo.png');
      const imageBuffer = await this.getBufferFromImagePath(imageURL);

      const imageId = workbook.addImage({
        buffer: imageBuffer,
        extension: 'png',
      });

      worksheet.addImage(imageId, {
        tl: { col: 0, row: 1 },
        ext: { width: 350, height: 100 }
      });

      // Save Excel file

      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }), "GanttData.xlsx");
      });


    },
    async getBufferFromImagePath(imagePath) {
      try {
        const response = await fetch(imagePath);
        const arrayBuffer = await response.arrayBuffer();
        return Buffer.from(arrayBuffer);
      } catch (error) {
        console.error('Error al leer la imagen:', error);
      }
    },
    formatoFecha(date) {
      var d = new Date(date);
      var day = d.getDate().toString().padStart(2, '0');
      var month = (d.getMonth() + 1).toString().padStart(2, '0'); // Adding 1 because months are 0-based
      var year = d.getFullYear();
      return day + '-' + month + '-' + year;
    },
    // Function to generate an array of task colors
    generateTaskColors(count) {
      var colors = [];
      for (var i = 0; i < count; i++) {
        // Generate a random color (you can replace this with your logic to get unique colors)
        var color = this.getRandomColor();
        colors.push(color);
      }
      return colors;
    },
    // Function to generate a random color
    getRandomColor() {
      var letters = '0123456789ABCDEF';
      var color = '';
      for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    },
    // Function to add month columns and apply styling to the timeline using ExcelJS
    addMonthColumnsExcelJS(worksheet, excelData) {

      // Find the oldest and newest dates from ganttData
      var allDates = excelData.reduce((acc, task) => {
        return acc.concat(new Date(task[1]), new Date(task[2]));
      }, []);
      //console.log(allDates);

      this.firstTaskStartDate = new Date(Math.min.apply(null, allDates));
      var lastTaskEndDate = new Date(Math.max.apply(null, allDates));

      if (this.tipoEscala === 'months') {
        // Calculate the number of months between the oldest and newest dates
        var numberOfMonths = this.calculateMonthsDifference(this.firstTaskStartDate, lastTaskEndDate);

        var months = [];
        for (var i = 0; i <= numberOfMonths; i++) {
          var currentDate = new Date(this.firstTaskStartDate);
          currentDate.setMonth(this.firstTaskStartDate.getMonth() + i);

          // Handle potential overflow to the next year
          if (currentDate.getMonth() !== (this.firstTaskStartDate.getMonth() + i) % 12) {
            currentDate.setFullYear(this.firstTaskStartDate.getFullYear() + Math.floor((this.firstTaskStartDate.getMonth() + i) / 12));
            currentDate.setMonth((this.firstTaskStartDate.getMonth() + i) % 12);
          }

          months.push(this.getMonthLabel(currentDate));
        }

        months.forEach((month, index) => {
          var colIndex = index + 4; // Start from the 4th column
          // Add month label to the header row
          worksheet.getRow(8).getCell(colIndex).value = month;
          worksheet.getRow(8).getCell(colIndex).numFmt = '0';
          worksheet.getColumn(colIndex).width = 15;
          this.applyTimelineRowStyle(worksheet, colIndex, excelData);

        });

      } else if (this.tipoEscala === 'days') {
        var numberOfDays = this.calculateDaysDifference(this.firstTaskStartDate, lastTaskEndDate);
        var days = [];
        var millisecondsInDay = 24 * 60 * 60 * 1000;

        for (i = 0; i <= numberOfDays; i++) {
          currentDate = new Date(this.firstTaskStartDate.getTime() + i * millisecondsInDay);

          // Agregar la cadena del día al array
          days.push(this.getDayLabel(currentDate));
        }

        days.forEach((day, index) => {
          var colIndex = index + 4;
          worksheet.getRow(8).getCell(colIndex).value = day;
          this.applyTimelineRowStyle(worksheet, colIndex, excelData);
        });


      } else {
        var numberOfWeeks = this.calculateWeeksDifference(this.firstTaskStartDate, lastTaskEndDate);
        var weeks = [];
        var millisecondsInWeek = 7 * 24 * 60 * 60 * 1000;
        for (i = 0; i <= numberOfWeeks; i++) {
          currentDate = new Date(this.firstTaskStartDate.getTime() + i * millisecondsInWeek);
          // Agregar la cadena de la semana al array
          weeks.push(this.getWeekLabel(currentDate));
        }

        weeks.forEach((week, index) => {
          var colIndex = index + 4;
          worksheet.getRow(8).getCell(colIndex).value = week;
          worksheet.getRow(8).getCell(colIndex).numFmt = '0';
          worksheet.getColumn(colIndex).width = 20;
          this.applyTimelineRowStyle(worksheet, colIndex, excelData);

        });
      }

    },
    calculateMonthsDifference(date1, date2) {
      var months;
      months = (date2.getUTCFullYear() - date1.getUTCFullYear()) * 12;
      months -= date1.getUTCMonth();
      months += date2.getUTCMonth();
      return months <= 0 ? 0 : months;
    },
    calculateWeeksDifference(date1, date2) {
      // Convert both dates to UTC to avoid timezone issues
      var utcDate1 = new Date(Date.UTC(date1.getFullYear(), date1.getMonth(), date1.getDate()));
      var utcDate2 = new Date(Date.UTC(date2.getFullYear(), date2.getMonth(), date2.getDate()));

      // Calculate the difference in milliseconds
      var differenceMs = utcDate2 - utcDate1;

      // Convert the difference to weeks
      var differenceWeeks = Math.floor(differenceMs / (1000 * 60 * 60 * 24 * 7));

      return differenceWeeks;
    },
    calculateDaysDifference(date1, date2) {
      var days;
      var differenceMs = date2.getTime() - date1.getTime();
      days = Math.floor(differenceMs / (1000 * 60 * 60 * 24));
      return days <= 0 ? 0 : days;
    },
    // Function to get Excel column name from index
    getExcelColumnName(index) {
      var columnName = '';
      while (index > 0) {
        var remainder = (index - 1) % 26;
        columnName = String.fromCharCode(65 + remainder) + columnName;
        index = (index - 1) / 26 | 0;
      }
      //console.log(columnName);
      return columnName || undefined;
    },
    // Function to get month label
    getMonthLabel(date) {
      var monthNames = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
      var d = new Date(date);
      return `${monthNames[d.getMonth()]} ${d.getFullYear().toString().substr(2)}`;
    },
    getWeekLabel(date) {
      var d = new Date(date);
      var weekNumber = this.getWeekNumber(d);
      var monthNames = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
      var monthName;
      var year = d.getFullYear();
      // Obtener el primer día de la semana
      var firstDayOfWeek = new Date(d);
      firstDayOfWeek.setDate(d.getDate() - d.getDay() + 1);

      // Obtener el último día de la semana
      var lastDayOfWeek = new Date(firstDayOfWeek);
      lastDayOfWeek.setDate(firstDayOfWeek.getDate() + 6);

      // Verificar si el primer día de la semana pertenece a otro mes
      if (firstDayOfWeek.getMonth() !== d.getMonth()) {
        // Si es así, significa que estamos en la última semana del mes anterior
        monthName = monthNames[firstDayOfWeek.getMonth()];
        year = firstDayOfWeek.getFullYear();
        weekNumber = this.getWeekNumber(firstDayOfWeek);
      } else if (lastDayOfWeek.getMonth() !== d.getMonth()) {
        // Si el último día de la semana pertenece a otro mes, estamos en la primera semana del mes siguiente
        monthName = monthNames[firstDayOfWeek.getMonth()];
        year = firstDayOfWeek.getFullYear();
        //weekNumber = 1;
      } else {
        // De lo contrario, estamos dentro del mes actual
        monthName = monthNames[d.getMonth()];
      }
      return `Sem ${weekNumber} ${monthName} ${year}`;
    },

    // Función auxiliar para obtener el número de semana en un año
    getWeekNumber(date) {
      var d = new Date(date);
      d.setHours(0, 0, 0, 0);
      var monthStart = new Date(d.getFullYear(), d.getMonth(), 1);
      var monthFirstDay = monthStart.getDay();
      var weekNumber = Math.ceil((d.getDate() + monthFirstDay) / 7);
      return weekNumber;
    },

    getDayLabel(date) {
      var d = new Date(date);
      //var dayOfWeek = d.toLocaleString('es-ES', { weekday: 'long' });
      // Convertir la primera letra del día de la semana a mayúscula
      //var capitalizedDayOfWeek = dayOfWeek.charAt(0).toUpperCase() + dayOfWeek.slice(1);
      var dayOfMonth = d.getDate();
      var monthNames = ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"];
      var monthName = monthNames[d.getMonth()];
      var year = d.getFullYear().toString().slice(-2);

      return `${dayOfMonth} ${monthName} ${year}`;
    },


    getMonthNumber(date) {
      var d = new Date(date);
      return d.getUTCMonth() + 1;
    },
    getFirstDayOfPreviousMonth(originalDate) {
      // Create a copy of the original date
      const previousMonth = new Date(originalDate);

      // Subtract one month from the original date
      previousMonth.setMonth(originalDate.getMonth() - 1);

      // If the resulting month is December of the previous year, adjust the year
      if (previousMonth.getMonth() === 11) {
        previousMonth.setFullYear(originalDate.getFullYear() - 1);
      }

      // Set the day to 1 to get the first day of the month
      previousMonth.setDate(1);

      return previousMonth;
    },
    getFirstDayOfPreviousWeek(originalDate) {
      // Create a copy of the original date
      const previousWeek = new Date(originalDate);

      // Subtract 7 days (1 week) from the original date
      previousWeek.setDate(originalDate.getDate() - 7);

      // Check if the resulting week belongs to the previous year
      if (previousWeek.getFullYear() < originalDate.getFullYear()) {
        // Adjust the year if necessary
        previousWeek.setFullYear(originalDate.getFullYear() - 1);
      }

      return previousWeek;
    },
    getMonthsFromFixedStartDate(date) {
      var fixedStartDate = this.getFirstDayOfPreviousMonth(this.firstTaskStartDate);
      //console.log(fixedStartDate);
      var months = this.calculateMonthsDifference(fixedStartDate, date);
      return months;
    },
    getWeeksFromFixedStartDate(date) {
      var fixedStartDate = this.getFirstDayOfPreviousWeek(this.firstTaskStartDate);
      //console.log(fixedStartDate);
      var weeks = this.calculateWeeksDifference(fixedStartDate, date);
      return weeks;
    },
    getDaysFromFixedStartDate(date) {
      var days = this.calculateDaysDifference(this.firstTaskStartDate, date);
      return days;
    },
    applyTimelineRowStyle(worksheet, colIndex, excelData) {
      for (var rowIndex = 2; rowIndex <= excelData.length + 1; rowIndex++) {
        var taskStart = new Date(excelData[rowIndex - 2][1]);
        var taskEnd = new Date(excelData[rowIndex - 2][2]);
        var colorP = 'FF228B22';
        var rowExcel = rowIndex + 7;
        var varStart = 0;
        var varEnd = 0;

        if (this.tipoEscala === 'months') {
          varStart = this.getMonthsFromFixedStartDate(taskStart);
          varEnd = this.getMonthsFromFixedStartDate(taskEnd);
        } else if (this.tipoEscala === 'days') {
          varStart = this.getDaysFromFixedStartDate(taskStart);
          varEnd = this.getDaysFromFixedStartDate(taskEnd);
        } else {
          varStart = this.getWeeksFromFixedStartDate(taskStart);
          varEnd = this.getWeeksFromFixedStartDate(taskEnd);
        }
        //var colorP = this.taskColors[rowIndex - 2];

        // Check if the column corresponds to the task timeline
        if (colIndex >= varStart + 3 && colIndex <= varEnd + 3) {
          // Adjust the start and end positions for the first and last tasks

          var startCol = Math.max(varStart + 3, colIndex);

          var endCol = Math.min(varEnd + 3, colIndex);

          for (var i = startCol; i <= endCol; i++) {
            var cell = worksheet.getCell(`${this.getExcelColumnName(i)}${rowExcel}`);
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: colorP }, // Change the color as needed
            };
          }
        }
      }
    },

    exportGantt() {

      exportGanttToPdf({
        component: this.gantt,
        createDocumentMethod: (args) => new jsPDF(args),
        landscape: 'true',
        exportMode: 'all',
        dateRange: "visible",
        margins: { left: 0, top: 180, right: 0, botoom: 0 }
      }).then((doc) => {
        doc.setProperties({
          title: 'Title',
          subject: 'This is the subject',
          author: 'James Hall',
          keywords: 'generated, javascript, web 2.0, ajax',
          creator: 'MEEE'
        });

        doc.setPage(1);
        doc.setFontSize(20);

        var img = new Image();
        img.src = require("../assets/logo.png");
        doc.addImage(img, "PNG", 0, 0, 290, 140);

        doc.setFontSize(12);
        doc.text("PLANTA:", 15, 168);
        doc.text("FECHA:", 560, 168);

        doc.text(this.pdfRazon ? this.pdfRazon : '', 85, 168);
        doc.text(this.pdfFechaInicio ? this.pdfFechaInicio : '', 625, 168);
        doc.text("PLAN DE TRABAJO", 300, 148);
        doc.save('gantt.pdf')
      });
    },

    onContextMenuPreparing(e) {
      e.cancel = true;
    },
    onTaskDeleted(e) {
      let err = 0;
      if (e.key != 0) {


        let myJSON = JSON.stringify({
          pid: e.key,
          pot: this.vidot
        });



        if (e.key != 0) {

          if (err == 0) {


            this.ordentrabajoplanService.deleteOrdenTrabajoPlan(myJSON).then(data => {

              if (data.err == 0) {
                this.type = 'success';
                this.message = 'Se ha eliminado el registro';
                this.isVisible = true;

                notify({
                  message: this.message,
                }, this.type, 5000);
                this.popupVisible = false;
                this.loadDataInfo();
              } else {

                this.type = 'error';
                this.message = 'Ocurrio un error ' + data.msg;
                this.isVisible = true;
                notify({
                  message: 'Ocurrio un error ' + data.msg,
                }, this.type, 4000);


              }


            });




          } else {
            this.type = 'error';
            this.message = 'error';
            this.isVisible = true;
            notify({
              message: this.message,
            }, this.type, 4000);

          }
          e.cancel = true;
        }
      }
    },

    downloadfileFundamento(e) {
      let name_file = e.row.data.ruta;
      

      axios({
        url: process.env.VUE_APP_APIURL + '/api/archivodownload?name_file=' + name_file,
        method: 'GET',
        responseType: 'blob',

      }).then((res) => {
        var url = window.URL.createObjectURL((res.data));
        var namepdf = name_file;

        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', namepdf);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });



    },
    cargarArchivoCliente(e){
        
        
        this.frm_cargali = e.row.data.id;
        this.frm_cargalidescripcion = e.row.data.descripcion;
        if(e.row.data.responsabilidadentregaid==3){
          this.frm_cargapermite='S';
        }
        this.obtieneDocumentosLI(); 
        this.popupVisibleCargaLI = true;
        
    },
    obtieneDocumentosLI(){
      let myJSON = JSON.stringify({
        pordentrabajolistadoinformacionid: this.frm_cargali,
      });
      this.ListadoInformacionService.getListadoDocumento(myJSON).then(data => {
        this.dataSourceDocumentoLI = data;
      });
    },
    cargarArchivoClienteLD(e){
        
        console.log(e.row.data);
        this.frm_cargald = e.row.data.id;
        this.frm_cargalddescripcion = e.row.data.descripcion;
        if(e.row.data.responsabilidadentregaid==3){
          this.frm_cargapermite='S';
        }
        this.obtieneDocumentosLD(); 
        this.popupVisibleCargaLD = true;
        
    },
    obtieneDocumentosLD(){
      let myJSON = JSON.stringify({
        pordentrabajolistadodocumentacionid: this.frm_cargald,
      });
      this.ListadoDocumentacionService.getListadoDocumento(myJSON).then(data => {
        this.dataSourceDocumentoLD = data;
      });
    },


    vincularFundamento(e) {

      this.frm_ed_id = e.row.data.guiaevaluacionestandarid;
      this.frm_ed_estandar = e.row.data.descripcion;

      this.loadDataInfoVinculos();

    },
    loadDataInfoVinculos() {

      let myJSON = JSON.stringify({
        pguiaevaluacionestandarid: this.frm_ed_id,
      });

      console.log(myJSON);
      this.GuiaEvaluacionEstandarFundamentoService.getGuiaEvaluacionEstandarLey(myJSON).then(data => this.dataSourceFundamentoEstandar = data);
      this.leyService.getLey().then(data => this.dataSourceLey = data);

      this.popupVisibleFundamento = true;
    },

    onTaskInserted(e) {


      let err = 0;
      let msgerror = '';

      let vfechainicio = '';
      let vfechafin = '';
      let vprogreso = '0';
      let vdescripcion = '';
      let vpadre = '';




      // Si esta indefinido no hubo cambio
      // De lo contrario si hubo cambio

      if (e.values.parentId === undefined || e.values.parentId === '' || e.values.parentId === 'null' || e.values.parentId === null) {
        vpadre = "-1";

      }
      else {
        vpadre = e.values.parentId;
      }
      vdescripcion = e.values.title;
      vfechainicio = moment(e.values.start).format('YYYY-MM-DD');
      vfechafin = moment(e.values.end).format('YYYY-MM-DD');



      let myJSON = JSON.stringify({

        pordentrabajoid: this.vidot,
        vpadre: vpadre,
        pdescripcion: vdescripcion,
        pfechainicio: vfechainicio,
        pfechafin: vfechafin,
        pprogreso: vprogreso
      });



      if (e.key != 0) {

        if (err == 0) {
          this.ordentrabajoplanService.createOrdenTrabajoPlan(myJSON).then(data => {

            if (data.err == 0) {
              this.type = 'success';
              this.message = 'Se ha insertado el registro';
              this.isVisible = true;

              notify({
                message: this.message,
              }, this.type, 5000);
              this.popupVisible = false;
              this.loadDataInfo();
            } else {

              this.type = 'error';
              this.message = 'Ocurrio un error ' + data.msg;
              this.isVisible = true;
              notify({
                message: 'Ocurrio un error ' + data.msg,
              }, this.type, 4000);


            }


          });
        } else {
          this.type = 'error';
          this.message = msgerror;
          this.isVisible = true;
          notify({
            message: this.message,
          }, this.type, 4000);

        }
        e.cancel = true;
      }



    },

    onTaskUpdating(e) {



      let err = 0;
      let msgerror = '';

      let vfechainicio = '';
      let vfechafin = '';
      let vprogreso = '';
      let vdescripcion = '';


      // Si esta indefinido no hubo cambio
      // De lo contrario si hubo cambio

      if (e.newValues.title === undefined || e.newValues.title === '' || e.newValues.title === 'null' || e.newValues.title === null) {
        vdescripcion = e.values.title;

      }
      else {
        if (e.newValues.title == undefined || e.newValues.title == null || e.newValues.title == '') {
          err = 1;
          msgerror = 'Ingresar descripción';
        }
        else {
          vdescripcion = e.newValues.title;

        }
      }


      if (e.newValues.start === undefined || e.newValues.start === '' || e.newValues.start === 'null' || e.newValues.start === null) {
        vfechainicio = moment(e.values.start).format('YYYY-MM-DD');

      }
      else {
        if (e.newValues.start == undefined || e.newValues.start == null || e.newValues.start == '') {
          err = 1;
          msgerror = 'Ingresar fecha inicio';
        }
        else {
          vfechainicio = moment(e.newValues.start).format('YYYY-MM-DD');

        }
      }

      if (e.newValues.end === undefined || e.newValues.end === '' || e.newValues.end === 'null' || e.newValues.end === null) {
        vfechafin = moment(e.values.end).format('YYYY-MM-DD');
      }
      else {
        if (e.newValues.end == undefined || e.newValues.end == null || e.newValues.end == '') {
          err = 1;
          msgerror = 'Ingresar fecha fin';
        }
        else {
          vfechafin = moment(e.newValues.end).format('YYYY-MM-DD');
        }
      }

      if (e.newValues.progress === undefined || e.newValues.progress === '' || e.newValues.progress === 'null' || e.newValues.progress === null) {


        vprogreso = e.values.progress;
      }
      else {
        if (e.newValues.progress === undefined || e.newValues.progress == null || e.newValues.progress == '') {
          err = 1;
          msgerror = 'Ingresar progreso';
        }
        else {

          vprogreso = e.newValues.progress;
        }
      }


      let myJSON = JSON.stringify({
        pid: e.key,
        pordentrabajoid: this.vidot,
        pdescripcion: vdescripcion,
        pfechainicio: vfechainicio,
        pfechafin: vfechafin,
        pprogreso: vprogreso
      });



      if (e.key != 0) {

        if (err == 0) {
          this.ordentrabajoplanService.updateOrdenTrabajoPlan(myJSON).then(data => {

            if (data.err == 0) {
              this.type = 'success';
              this.message = 'Se ha actualizado el registro';
              this.isVisible = true;

              notify({
                message: this.message,
              }, this.type, 5000);
              this.popupVisible = false;
              this.loadDataInfo();
            } else {

              this.type = 'error';
              this.message = 'Ocurrio un error ' + data.msg;
              this.isVisible = true;
              notify({
                message: 'Ocurrio un error ' + data.msg,
              }, this.type, 4000);


            }


          });
        } else {
          this.type = 'error';
          this.message = msgerror;
          this.isVisible = true;
          notify({
            message: this.message,
          }, this.type, 4000);

        }
        e.cancel = true;
      }
    },
    onResourceAssigned(e) {

      let myJSON = JSON.stringify({
        pordentrabajoid: this.vidot,
        pordentrabajoplanid: e.values.taskId,
        pclientecontactoid: e.values.resourceId,

      });


      if (e.values.taskID != 0) {
        this.ordentrabajoplanService.createOrdenTrabajoPlanRecursoTarea(myJSON).then(data => {
          if (data.err == 0) {
            this.type = 'success';
            this.message = 'Se ha actualizado el registro';
            this.isVisible = true;

            notify({
              message: this.message,
            }, this.type, 5000);
            this.popupVisible = false;
            this.loadDataInfo();
          } else {

            this.type = 'error';
            this.message = 'Ocurrio un error ' + data.msg;
            this.isVisible = true;
            notify({
              message: 'Ocurrio un error ' + data.msg,
            }, this.type, 4000);


          }


        });
      }
    }, onResourceUnassigned(e) {

      let myJSON = JSON.stringify({
        pid: e.key,


      });

      if (e.key != 0) {
        this.ordentrabajoplanService.deleteOrdenTrabajoPlanRecursoTarea(myJSON).then(data => {
          if (data.err == 0) {
            this.type = 'success';
            this.message = 'Se ha actualizado el registro';
            this.isVisible = true;

            notify({
              message: this.message,
            }, this.type, 5000);
            this.popupVisible = false;
            this.loadDataInfo();
          } else {

            this.type = 'error';
            this.message = 'Ocurrio un error ' + data.msg;
            this.isVisible = true;
            notify({
              message: 'Ocurrio un error ' + data.msg,
            }, this.type, 4000);


          }


        });
      }
    },

    editRegisterCambioEtapa(e) {
      let id = this.vidot;
      let idusuario = JSON.parse(Cookies.get("ets_user")).id;
      let myJSON = JSON.stringify({
        pid: id,
        pusuario: idusuario,
        petapaid: e.data.etapaid,
      });

      this.ordentrabajoresultadoService.getOrdenTrabajoCierreEtapa(myJSON).then(data => {

        if (data.err == 0) {

          notify({
            message: 'OT Etapa ha sido actualizada',
          }, 'success', 9000);

          this.loadDataInfo();
        } else {

          notify({
            message: 'Ocurrio un error ' + data.msg,
            position: {
              my: 'center top',
              at: 'center top',
            },
          }, 'error', 9000);


        }


      });


    },
    editRegisterCambioEstatus(e) {

      let idusuario = JSON.parse(Cookies.get("ets_user")).id;
      let myJSON = JSON.stringify({
        pid: this.vidot,
        pusuario: idusuario,
        pobservacion: e.data.observacion,
        pestatusid: e.data.estatusid,
      });

      this.ordentrabajoresultadoService.getOrdenTrabajoCierre(myJSON).then(data => {

        if (data.err == 0) {

          notify({
            message: 'OT ha sido actualizada',
          }, 'success', 9000);
          this.loadDataInfo();
        } else {

          notify({
            message: 'Ocurrio un error ' + data.msg,
            position: {
              my: 'center top',
              at: 'center top',
            },
          }, 'error', 9000);


        }


      });

    },

    handleValueChange1(e) {


      if (e.value == true) {
        this.eca_si = true;
        this.eca_no = false;
        this.eca_parcial = false;
        this.eca_noaplica = false;
        this.eca_aplicagantt = false;
      }
      else {
        this.eca_si = false;
      }


    },

    handleValueChange2(e) {

      if (e.value == true) {
        this.eca_no = true;
        this.eca_si = false;
        this.eca_parcial = false;
        this.eca_noaplica = false;
      }
      else {
        this.eca_no = false;
      }


    },
    handleValueChange3(e) {


      if (e.value == true) {
        this.eca_parcial = true;
        this.eca_no = false;
        this.eca_si = false;
        this.eca_noaplica = false;
      }
      else {
        this.eca_parcial = false;
      }



    },
    handleValueChange4(e) {

      if (e.value == true) {
        this.eca_noaplica = true;
        this.eca_no = false;
        this.eca_si = false;
        this.eca_parcial = false;
        this.eca_aplicagantt = false;
      }
      else {
        this.eca_noaplica = false;
      }




    },
    onExporting() {
      const doc = new jsPDF('portrait', 'mm', 'a4');
      const fecha = new Date();
      var mes = this.mesFecha(fecha.getMonth());
      var cultimo = this.datosPDFResultados.length - 1;

      this.datosPDFResultados.forEach(
        dataR => {
          if (dataR.materia != "Total") {
            if (dataR.id === cultimo) {
              dataR.cumplimiento2 = "Cumplimiento (%)";
            } else {
              dataR.cumplimiento2 = "";
            }
          } else {
            dataR.cumplimiento2 = this.formatoCumplimiento(dataR.cumplimiento.toString());
          }
        }
      );

      var pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
      doc.setFontSize(20);

      var img = new Image();
      img.src = require("../assets/logo.png");
      doc.addImage(img, "PNG", 5, 5, 80, 40);

      doc.setFontSize(12);
      doc.text("PLANTA:", 15, 58, { align: "left" });
      doc.text("FECHA:", 160, 58, { align: "right" });
      doc.text(this.pdfRazon, 34, 58, { align: "left" });
      doc.text(this.pdfFechaInicio, 166, 58, { align: "left" });


      doc.text("RESULTADOS", pageWidth / 2, 48, { align: "center" });
      doc.setFontSize(9);
      doc.text("En la siguiente tabla se concentran, los valores, en porciento, del nivel de cumplimiento ambiental al " + fecha.getDay() + " de " + mes + " del " + fecha.getFullYear() + " .", 20, 65, { align: "left" });
      var columns_datos = [
        { title: "Capitulo/Seccion", dataKey: "materia" },
        { title: "Puntaje Máximo", dataKey: "puntajemaximo" },
        { title: "Puntaje Obtenido", dataKey: "puntajeobtenido" },
        { title: "", dataKey: "cumplimiento2" },
      ];

      doc.autoTable(columns_datos, this.datosPDFResultados, {
        startY: 70,
        finalY: 33 + 35,
        theme: 'plain',
        rowPageBreak: 'avoid',
        pageBreak: 'auto',
        halign: 'center',
        styles: {
          lineWidth: 0.5,
          lineColor: 0,
          valign: 'bottom',
        },
        headStyles: {
          minCellHeight: 10,
          fillColor: (211, 211, 211),
          halign: 'center',
        },
        columnStyles: {
          puntajemaximo: {
            halign: 'center',
            fontStyle: 'bold',
          },
          puntajeobtenido: {
            halign: 'center',
            fontStyle: 'bold',
          },
          cumplimiento2: {
            halign: 'center',
            fontStyle: 'bold',
          }
        },
      });
      doc.setFontSize(8);
      doc.text("Nota: Para calcular el puntaje máximo se descontaron los puntos asginados, donde se calificó como No Aplica. ", 20, 190, { align: "left" });

      doc.save('ResultadosECA.pdf');

    },
    onExporting2() {


      const doc = new jsPDF("landscape", "mm", "a4");
      var habilidades = [];
      let cont = 1;
      let datos = this.dataSourceEstandar;
      var pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
      doc.setFontSize(20);
      var img = new Image();
      img.src = require("../assets/logo.png");
      doc.addImage(img, "PNG", 5, 5, 80, 40);

      doc.text("EVALUACION DE CUMPLIMIENTO AMBIENTAL", pageWidth / 2, 48, { align: "center" });
      doc.setFontSize(12);

      doc.text("PLANTA:", 15, 58, { align: "left" });
      doc.text("FECHA:", 220, 58, { align: "right" });

      doc.text(this.pdfRazon, 34, 58, { align: "left" });
      doc.text(this.pdfFechaInicio, 224, 58, { align: "left" });

      var columns_datos = [
        { title: "No.", dataKey: "numero" },
        { title: "ESTANDAR", dataKey: "descripcion" },
        { title: "SI", dataKey: "valorsi2" },
        { title: "PARCIAL", dataKey: "valorparcial2" },
        { title: "NO", dataKey: "valorno2" },
        { title: "N.A.", dataKey: "noaplica2" },
        { title: "FUNDAMENTO JURIDICO", dataKey: "fundamentos" },
        { title: "RECOMENDACION", dataKey: "recomendacion" },
      ];


      habilidades.push("Agua de Suministro");
      habilidades.push("Descarga de Aguas Residuales");
      habilidades.push("Suelo y Subsuelo");
      habilidades.push("Ruido");
      habilidades.push("Aire");
      habilidades.push("Residuos Peligrosos");
      habilidades.push("Residuos Urbanos y Manejo Especial");
      habilidades.push("Riesgo Ambiental");
      habilidades.push("Impacto Ambiental");
      habilidades.push("Registro de Emisiones");
      habilidades.push("Permisos");
      habilidades.push("Administracion Ambiental");


      datos.forEach(
        data => {

          data.numero = cont;
          data.valorsi2 = this.formatoValorCheck(data.valorsi);
          data.valorno2 = this.formatoValorCheck(data.valorno);
          data.valorparcial2 = this.formatoValorCheck(data.valorparcial);
          data.noaplica2 = this.formatoValorCheck(data.noaplica);

          cont++;
        }
      );

      cont = 1;
      habilidades.forEach(
        hab => {

          let coordenada = 0
          if (cont != 1) {
            coordenada = doc.autoTable.previous.finalY;
          } else {
            coordenada = 10
          }


          doc.setFontSize(12);
          doc.setTextColor(40);
          doc.getFontList();
          doc.text(hab, 15, coordenada + 60);

          cont++;
          var datos_filtrados = datos.filter(data => data.habilidad.substring(2, 50) == hab);
          doc.autoTable(columns_datos, datos_filtrados, {
            theme: 'plain',
            rowPageBreak: 'avoid',
            pageBreak: 'auto',
            halign: 'center',
            cellWidth: 'wrap',
            finalY: 50,
            startY: coordenada + 65,
            styles: {
              lineWidth: 0.5,
              lineColor: 0,
              valign: 'bottom',
            },
            columnStyles: {
              valorsi2: {
                halign: 'center',
              },
              valorno2: {
                halign: 'center',
              },
              valorparcial2: {
                halign: 'center',
              },
              noaplica2: {
                halign: 'center',
              },
              fundamentos: {
                halign: 'center',
              },
            }
          });
        }
      );

      doc.save('ResultadoDetalleEvaluacion.pdf');

    },

    cambioResponsable(e) {





      let myJSON;
      myJSON = JSON.stringify({
        pordentrabajoid: this.vidot,
        pid: 0,
        ptiporesponsable: e.value
      });

      this.ordentrabajoresultadoService.cambioResponsable(myJSON).then(data => {



        if (data.err == 0) {
          notify({
            message: 'Registro ha sido actualizado',
          }, 'success', 9000);

        } else {

          notify({
            message: 'Ocurrio un error ' + data.msg,
            position: {
              my: 'center top',
              at: 'center top',
            },
          }, 'error', 9000);

        }
      });

    },

    onExporting3() {
      const doc = new jsPDF("landscape", "mm", "a4");
      var habilidades = [];
      let habilidad = "";
      let cont = 1;
      var posicion = 0;
      const pageHeight = doc.internal.pageSize.getHeight();

      let datos = this.dataSourceResultadosNO;
      let datos_multas = this.datosMultas;

      var pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
      doc.setFontSize(20);
      var img = new Image();
      img.src = require("../assets/logo.png");
      doc.addImage(img, "PNG", 5, 5, 80, 40);
      doc.setFontSize(12);
      doc.text("PLANTA:", 15, 58, { align: "left" });
      doc.text("FECHA:", 160, 58, { align: "right" });
      doc.text("NÚMERO OT: " + this.customer.numeroordentrabajo, 255, 58, { align: "right" });
      doc.text(this.pdfRazon, 34, 58, { align: "left" });
      doc.text(this.pdfFechaInicio, 166, 58, { align: "left" });

      doc.text("MULTAS PROBABLES", pageWidth / 2, 48, { align: "center" });

      var columns_datos = [
        { title: "Etapa", dataKey: "tema" },
        { title: "Infracción", dataKey: "infraccion" },
        { title: "Fundamento Legal", dataKey: "fundamentolegal" },
        { title: "Baja Mínima", dataKey: "bajamin" },
        { title: "Baja Maxima", dataKey: "bajamax" },
        { title: "Media Mínima", dataKey: "mediamin" },
        { title: "Media Maxima", dataKey: "mediamax" },
        { title: "Alta Mínima", dataKey: "altamin" },
        { title: "Alta Maxima", dataKey: "altamax" },
      ];

      var columns_summultas = [
        { title: "Multa Mínima", dataKey: "multamin" },
        { title: "Multa Media", dataKey: "multamed" },
        { title: "Multa Alta", dataKey: "multaalta" },
      ];

      
  
      var min=this.sumamin;
      var med=this.sumamed;
      var max=this.sumamax;
      var datos_summultas = [
        { multamin: min, multamed: med, multaalta: max}
      ];

      datos.forEach(
        data => {

          if (data.habilidad != habilidad) {
            habilidad = data.habilidad;

            habilidades.push(habilidad);
            cont = 1;
          }

          data.numero = cont;
          data.valorno2 = this.formatoValorCheck(data.valorno);

          cont++;
        }
      );

      let checkmultasvacias = datos_multas.filter(item => item.datos.length === 0);

      if(checkmultasvacias.length === datos_multas.length){

        doc.setFont('helvetica','bold');
        doc.text("NO SE IDENTIFICAN MULTAS", pageWidth / 2, 70, { align: "center" });

      } else {

        doc.autoTable(columns_summultas, datos_summultas, {
          theme: 'plain',
          rowPageBreak: 'avoid',
          pageBreak: 'auto',
          halign: 'center',
          startY: 65,
          styles: {
            lineWidth: 0.5,
            lineColor: 0,
            halign: 'center',
            cellWidth: 'wrap'
          },
          bodyStyles: {
            fontStyle: 'bold'
          }
        });
        
        
        

        //cont = 0;
        posicion = 90;

        habilidades.forEach(
          hab => {

            
            /*if (cont != 1) {
              coordenada = doc.autoTable.previous.finalY;
            } else {
              coordenada = 70
            }
            cont++;*/
            
            var datos_filtrados = datos.filter(data => data.habilidad == hab);
            var checkmultas = datos_multas.filter(item1 =>{
              var existe = datos_filtrados.some(item2 => item2.guiaevaluacionestandarid === item1.idVal);

              return existe && item1.datos.length > 0;
            });

            //console.log(checkmultas);

            if(checkmultas.length > 0){
            
              doc.setFontSize(12);
              doc.setTextColor(40);
              doc.setFont('helvetica','bold');
              doc.getFontList();
              doc.text(hab, 15, posicion);
              posicion = posicion + 10;

              datos_filtrados.forEach(
              datosf => {
                  doc.setFontSize(10);
                  doc.setFont('helvetica','normal');
                  var datos_multas_filtrados = datos_multas.filter(data => data.idVal === datosf.guiaevaluacionestandarid);
                  let datosFiltrados = datos_multas_filtrados.length > 0 ? datos_multas_filtrados[0].datos : [];

                  
                  if(datos_multas_filtrados[0].datos.length > 0){

                    posicion = this.checkPosicion(doc, posicion, pageHeight - 30);

                    let splitTitle = doc.splitTextToSize(datosf.descripcion, 230);
                    doc.text(splitTitle, 15, posicion);

                    if(splitTitle.length > 1){
                      posicion = posicion + (4 * splitTitle.length) + 6;
                    } else {
                      posicion = posicion + 10;
                    }

                    posicion = this.checkPosicion(doc, posicion, pageHeight - 60);
                    doc.autoTable(columns_datos, datosFiltrados, {
                      theme: 'plain',
                      rowPageBreak: 'avoid',
                      pageBreak: 'auto',
                      halign: 'center',
                      cellWidth: 'wrap',
                      startY: posicion,
                      styles: {
                        lineWidth: 0.5,
                        lineColor: 0,
                        valign: 'bottom',
                      },
                      columnStyles: {
                        halign: 'center'
                      }
                    });
                      
                    posicion = doc.autoTable.previous.finalY + 10;

                  }
                }
              );
            }

            posicion = this.checkPosicion(doc, posicion, pageHeight - 30);
            
          }
        );

      }

      doc.save('ResultadoDetalleEvaluacionMultas.pdf');
    },

    onExporting4() {
      const doc = new jsPDF("landscape", "mm", "a4");
      var habilidades = [];
      let habilidad = "";
      let cont = 1;

      let datos = this.dataSourceResultadosConRecomendacion;

      var pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
      doc.setFontSize(20);
      var img = new Image();
      img.src = require("../assets/logo.png");
      doc.addImage(img, "PNG", 5, 5, 80, 40);
      doc.setFontSize(12);
      doc.text("PLANTA:", 15, 58, { align: "left" });
      doc.text("FECHA:", 160, 58, { align: "right" });
      doc.text(this.pdfRazon, 34, 58, { align: "left" });
      doc.text(this.pdfFechaInicio, 166, 58, { align: "left" });

      doc.text("RECOMENDACIONES", pageWidth / 2, 48, { align: "center" });

      var columns_datos = [
        { title: "No.", dataKey: "numero" },
        { title: "PREGUNTA", dataKey: "descripcion" },
        { title: "NO", dataKey: "valorno2" },
        { title: "RECOMENDACION", dataKey: "recomendacion" },
        
      ];

      datos.forEach(
        data => {

          if (data.habilidad != habilidad) {
            habilidad = data.habilidad;

            habilidades.push(habilidad);
            cont = 1;
          }

          data.numero = cont;
          data.valorno2 = this.formatoValorCheck(data.valorno);

          cont++;
        }
      );

      cont = 1;
      habilidades.forEach(
        hab => {

          let coordenada = 0
          if (cont != 1) {
            coordenada = doc.autoTable.previous.finalY;
          } else {
            coordenada = 10
          }


          doc.setFontSize(12);
          doc.setTextColor(40);
          doc.getFontList();
          doc.text(hab, 15, coordenada + 60);

          cont++;
          var datos_filtrados = datos.filter(data => data.habilidad == hab);
          doc.autoTable(columns_datos, datos_filtrados, {
            theme: 'plain',
            rowPageBreak: 'avoid',
            pageBreak: 'auto',
            halign: 'center',
            cellWidth: 'wrap',
            finalY: 50,
            startY: coordenada + 65,
            styles: {
              lineWidth: 0.5,
              lineColor: 0,
              valign: 'bottom',
            },
            columnStyles: {
              valorsi2: {
                halign: 'center',
              },
              valorno2: {
                halign: 'center',
              },
              valorparcial2: {
                halign: 'center',
              },
              noaplica2: {
                halign: 'center',
              },
              fundamentos: {
                halign: 'center',
              },
            }
          });
        }
      );

      doc.save('ResultadoDetalleEvaluacionRecomendaciones.pdf');
    },
    checkPosicion(doc, posicion, pageHeight){
        if(posicion > pageHeight){
          doc.addPage();
          posicion = 30;
        } 
       return posicion;
    },
    obtenerMultas(data){
      var datosPregunta = data;
      datosPregunta.forEach(
        datos => {
          var datosMulta = [];
          datosMulta.idVal = datos.guiaevaluacionestandarid;
          let myJSON = JSON.stringify({
            pguievaluacionestandarid: datos.guiaevaluacionestandarid,

          });
          this.ordentrabajoresultadoService.getECAMulta(myJSON).then(data => {
            datosMulta.datos = data;
            this.datosMultas.push(datosMulta);

          });
        }
      );
      
    },
    formatoCumplimiento(fecha) {
      return fecha.length < 3 ? fecha.slice(0, 1) : fecha.slice(0, 2);
    },

    mesFecha(fecha) {
      var mes = "";
      switch (fecha) {
        case 0:
          mes = "Enero";
          break;
        case 1:
          mes = "Febrero";
          break;
        case 2:
          mes = "Marzo";
          break;
        case 3:
          mes = "Abril";
          break;
        case 4:
          mes = "Mayo";
          break;
        case 5:
          mes = "Junio";
          break;
        case 6:
          mes = "Julio";
          break;
        case 7:
          mes = "Agosto";
          break;
        case 8:
          mes = "Septiembre";
          break;
        case 9:
          mes = "Octubre";
          break;
        case 10:
          mes = "Noviembre";
          break;
        case 11:
          mes = "Diciembre";
          break;
      }
      return mes;
    },

    formatoValorCheck(x) {
      if (x) {
        return 'X';
      } else {
        return '';
      }
    },

    showPopup(data) {

      console.log(data);
      this.loading = true;

      this.popupVisible = true;

    },
    vermultas(e) {

      this.v_pop_multa = e.row.data.descripcion;
      let myJSON = JSON.stringify({
        pguievaluacionestandarid: e.row.data.guiaevaluacionestandarid,

      });
      this.ordentrabajoresultadoService.getECAMulta(myJSON).then(data => {
        
        this.dataSourceMultasConsulta = data;

      });
      this.multas_popupVisible=true;

},

    editECA(e) {



      this.eca_id = e.row.data.id;
      this.eca_materia = e.row.data.habilidad;
      this.eca_recomendacion = e.row.data.recomendacion;
      this.eca_observacion = e.row.data.observacion;
      this.eca_pregunta = e.row.data.descripcion;
      this.eca_fundamentos = e.row.data.fundamentos;
      this.eca_aplicagantt = e.row.data.aplicagantt;

      this.eca_si = e.row.data.valorsi;
      this.eca_no = e.row.data.valorno;
      this.eca_parcial = e.row.data.valorparcial;
      this.eca_noaplica = e.row.data.noaplica;


      this.showPopup({ ...e.row.data });

    },
    editECAReset(e) {
      let id = e.row.data.id;

      let myJSON = JSON.stringify({
        pid: id,

      });

      let result = confirm("<i>Esta seguro que desea reiniciar los valores?</i>", "Confirmacion");
      result.then((dialogResult) => {
        if (dialogResult) {


          this.ordentrabajoresultadoService.updateOrdenTrabajoResultadoReset(myJSON).then(data => {
            if (data.err == 0) {
              this.type = 'success';
              this.message = 'Se ha reinicializado el registro';
              this.isVisible = true;

              notify({
                message: this.message,
              }, this.type, 5000);
              this.popupVisible = false;
              this.loadDataInfo();
            } else {

              this.type = 'error';
              this.message = 'Ocurrio un error ' + data.msg;
              this.isVisible = true;
              notify({
                message: 'Ocurrio un error ' + data.msg,
              }, this.type, 4000);


            }


          });
        }
      });

    },
    saveClick() {
      let vsi = this.eca_si == true ? 1 : 0;
      let vno = this.eca_no == true ? 1 : 0;
      let vparcial = this.eca_parcial == true ? 1 : 0;
      let vnoaplica = this.eca_noaplica == true ? 1 : 0;
      let vaplicagantt = this.eca_aplicagantt == true ? 1 : 0;
      let idusuario = JSON.parse(Cookies.get("ets_user")).id;
      let myJSON = JSON.stringify({
        pid: this.eca_id,
        pvalorsi: vsi,
        pvalorno: vno,
        pvalorparcial: vparcial,
        pnoaplica: vnoaplica,
        pusuarioregistroid: idusuario,
        pdiashabiles: 0,
        pobservacion: this.eca_observacion,
        precomendacion: this.eca_recomendacion,
        paplicagantt: vaplicagantt,
      });

      /** VALIDACIONES DE CAPTURA EN FUNCION DE LO CAPTURADO */
      /**
       * 1 Debe tener un resultado capturado
       * 2. Si el valor es SI y No Aplica la recomendacion y observacion no son necesarias
       * 3. Si el valor es parcial y NO la recomendacion y observacion son obligatorias
       */
      let err = 0;
      let msgerror = '';

      if (this.eca_si == false && this.eca_no == false && this.eca_parcial == false && this.eca_noaplica == false) {
        err = 1;
        msgerror = 'Ingresar resultado';
      }
      /**
       else {
        if (this.eca_no == true || this.eca_parcial == true) {

          if (this.eca_observacion == '' && this.eca_recomendacion == '') {
            err = 1;
            msgerror = 'Ingresar observacion y/o recomendacion';
          }
        }
      }
      **/

      if (err == 0) {
        if (this.eca_si == true) {
          this.eca_observacion = '';
          this.eca_recomendacion = '';
        }
      }

      if (err == 0) {
        this.ordentrabajoresultadoService.updateOrdenTrabajoResultado(myJSON).then(data => {

          if (data.err == 0) {
            this.type = 'success';
            this.message = 'Se ha actualizado el registro';
            this.isVisible = true;

            notify({
              message: this.message,
            }, this.type, 5000);
            this.popupVisible = false;
            this.loadDataInfo();
          } else {

            this.type = 'error';
            this.message = 'Ocurrio un error ' + data.msg;
            this.isVisible = true;
            notify({
              message: 'Ocurrio un error ' + data.msg,
            }, this.type, 4000);


          }


        });
      } else {
        this.type = 'error';
        this.message = msgerror;
        this.isVisible = true;
        notify({
          message: this.message,
        }, this.type, 4000);

      }





    },
    downloadfile(e) {
      let name_file = e.row.data.ruta;
      let tipo_archivo = e.row.data.idtipoarchivo;
      
      let vurl = process.env.VUE_APP_APIURL + '/api/archivodownload?name_file=' + name_file + '&tipoarchivo='+ tipo_archivo;
      console.log('TURL',vurl);
      axios({
        url: vurl,
        method: 'GET',
        responseType: 'blob',

      }).then((res) => {
        var url = window.URL.createObjectURL((res.data));
        var namepdf = name_file;

        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', namepdf);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });



    },
    onUploaded(e) {
     
    
      let id = this.frm_cargali;
      let res = JSON.parse(e.request.responseText);
      let archivosubido = res.image_name;
      let idusuario = JSON.parse(Cookies.get("ets_user_ec")).id;
      let myJSON = JSON.stringify({
        ptipodocumentoid: this.valtipodocumento,
        pnombredocumento: this.valnombredocumento,
        pruta: archivosubido,
        pordentrabajolistadoinformacionid: id,
        pusuarioregistroid: idusuario
      });
     
         console.log(myJSON);

      
      let err = 0;
      let msgerror = '';

      if (this.valtipodocumento == null || this.valtipodocumento == '' || this.valtipodocumento == 'undefined') {
        err = 1;
        msgerror = 'Ingresar tipo documento';
      }

      if (err == 0) {
        if (this.valnombredocumento == null || this.valnombredocumento == '' || this.valnombredocumento == 'undefined') {
          err = 1;
          msgerror = 'Ingresar nombre documento';
        }
      }

      if (err == 0) {
        this.ListadoInformacionService.createListadoDocumento(myJSON).then(data => {

          if (data.err == 0) {
            this.type = 'success';
            this.message = 'Se ha ingresado el documento';
            this.isVisible = true;

            notify({
              message: this.message,
            }, this.type, 5000);
            //this.popupVisible = false;
            this.valtipodocumento = null;
            this.valnombredocumento = null;
            this.obtieneDocumentosLI();
          } else {

            this.type = 'error';
            this.message = 'Ocurrio un error ' + data.msg;
            this.isVisible = true;
            notify({
              message: 'Ocurrio un error ' + data.msg,
            }, this.type, 4000);


          }


        });
      } else {
        this.type = 'error';
        this.message = msgerror;
        this.isVisible = true;
        notify({
          message: this.message,
        }, this.type, 4000);

      }
 
       
    },

    onUploadedld(e) {
     
    
     let id = this.frm_cargald;
     let res = JSON.parse(e.request.responseText);
     let archivosubido = res.image_name;
     let idusuario = JSON.parse(Cookies.get("ets_user_ec")).id;
     let myJSON = JSON.stringify({
       ptipodocumentoid: this.valtipodocumento,
       pnombredocumento: this.valnombredocumento,
       pruta: archivosubido,
       pordentrabajolistadodocumentacionid: id,
       pusuarioregistroid: idusuario
     });
    
        console.log(myJSON);

     
     let err = 0;
     let msgerror = '';

     if (this.valtipodocumento == null || this.valtipodocumento == '' || this.valtipodocumento == 'undefined') {
       err = 1;
       msgerror = 'Ingresar tipo documento';
     }

     if (err == 0) {
       if (this.valnombredocumento == null || this.valnombredocumento == '' || this.valnombredocumento == 'undefined') {
         err = 1;
         msgerror = 'Ingresar nombre documento';
       }
     }

     if (err == 0) {
       this.ListadoDocumentacionService.createListadoDocumento(myJSON).then(data => {

         if (data.err == 0) {
           this.type = 'success';
           this.message = 'Se ha ingresado el documento';
           this.isVisible = true;

           notify({
             message: this.message,
           }, this.type, 5000);
           //this.popupVisible = false;
           this.valtipodocumento = null;
           this.valnombredocumento = null;
           this.obtieneDocumentosLD();
         } else {

           this.type = 'error';
           this.message = 'Ocurrio un error ' + data.msg;
           this.isVisible = true;
           notify({
             message: 'Ocurrio un error ' + data.msg,
           }, this.type, 4000);


         }


       });
     } else {
       this.type = 'error';
       this.message = msgerror;
       this.isVisible = true;
       notify({
         message: this.message,
       }, this.type, 4000);

     }

      
   },
    onUploadError(e) {
      
      let xhttp = e.request;
      if (xhttp.status === 400) {
        e.message = e.error.responseText;
      }
      if (xhttp.readyState === 4 && xhttp.status === 0) {
        e.message = "Conexión rechazada";
      }
    },
    onClickCierraEtapa() {

      let idusuario = JSON.parse(Cookies.get("ets_user")).id;
      let myJSON = JSON.stringify({
        pid: this.vidot,
        pusuario: idusuario,
        petapaid: this.valetapa,
      });

      this.ordentrabajoresultadoService.getOrdenTrabajoCierreEtapa(myJSON).then(data => {

        if (data.err == 0) {

          notify({
            message: 'OT Etapa ha sido actualizada',
          }, 'success', 9000);

          this.loadDataInfo();
        } else {

          notify({
            message: 'Ocurrio un error ' + data.msg,
            position: {
              my: 'center top',
              at: 'center top',
            },
          }, 'error', 9000);


        }


      });


    },
    onClickCierra() {

      let idusuario = JSON.parse(Cookies.get("ets_user")).id;
      let myJSON = JSON.stringify({
        pid: this.vidot,
        pusuario: idusuario,
        pobservacion: this.valcierre,
      });

      this.ordentrabajoresultadoService.getOrdenTrabajoCierre(myJSON).then(data => {

        if (data.err == 0) {

          notify({
            message: 'OT ha sido actualizada',
          }, 'success', 9000);
          this.$router.push({ name: 'ordentrabajoresultadoconguia', params: { id: this.vidot } });
        } else {

          notify({
            message: 'Ocurrio un error ' + data.msg,
            position: {
              my: 'center top',
              at: 'center top',
            },
          }, 'error', 9000);


        }


      });

    },
    loadDataInfo() {

      let myJSON_Com = JSON.stringify({
        pordentrabajoid: this.vidot,
      });
      this.ordentrabajoresultadoService.getResponsable(myJSON_Com).then(data => {
        this.dataSourceResponsable = data
      });


      this.responsabilidadentregaService.getResponsabilidadEntrega().then(data => this.dataSourceResponsabilidadEntrega = data);
      let id = this.vidot;
      let myJSON = JSON.stringify({
        pid: id,

      });

      this.ordentrabajoresultadoService.getECAMultSuma(myJSON_Com).then(data => {
        this.datosSuma = data;
        this.sumamin=this.datosSuma[0].multamin;
      this.sumamed=this.datosSuma[0].multamed;
      this.sumamax=this.datosSuma[0].multaalta;
      });

      // GANTT

      this.ordentrabajoplanService.getOrdenTrabajoPlanTareas(myJSON).then(data => {
        this.tasks = data;
        this.obtenerFechaMasAntigua(data);
      }
      );

      this.ordentrabajoplanService.getOrdenTrabajoPlanDependencias(myJSON).then(data => {
        this.dependencies = data
      }
      );



      this.ordentrabajoplanService.getOrdenTrabajoPlanContactoCliente(myJSON).then(data => { this.resources = data });



      this.ordentrabajoplanService.getOrdenTrabajoPlanRecursoTarea(myJSON).then(data => {
        this.resourceAssignments = data
      }
      );

      // FIN GANTT

      this.ordentrabajoresultadoService.getOrdenTrabajoResultadoPorId(myJSON).then(data => {
        this.customer = data
        this.pdfRazon = data.razonsocial;
        this.pdfFechaInicio = data.fechainicioplaneada;
        this.vidotresponsable = data.responsable;
      }

      );

      this.ordentrabajoresultadoService.getOrdenTrabajoResultadoECA(myJSON).then(data => {
        this.dataSourceECA = data
        this.datosPDFResultados = data
      }

      );

      this.tipodocumentoService.getTipoDocumento().then(data => {
        this.dataSourceDocumento = data;

      });


      this.ordentrabajoresultadoService.getOrdenTrabajoResultadoPorId2(myJSON).then(data => {
        this.dataSourceCambioEstatus = data
      }

      );



      this.ordentrabajoresultadoService.getOrdenTrabajoResultadoPorId2(myJSON).then(data => {
        this.dataSourceEtapaCierre = data
      }

      );

      this.ordentrabajoresultadoService.getOrdenTrabajoHistorialCambios(myJSON).then(data => {
        this.dataSourceHistorial = data;
      });
      this.ordentrabajodocumentoService.getOrdenTrabajoDocumento(myJSON).then(data => {
        this.dataSourceDocumentacion = data;
      });


      this.ordentrabajoresultadoService.getOrdenTrabajoResultadoDetalle(myJSON).then(data => {
        data.forEach(
          element => {

            if (element.valorsi == 1) {
              element.valorsi = true;
            } else {
              element.valorsi = false;
            }
            if (element.valorno == 1) {
              element.valorno = true;
            } else {
              element.valorno = false;
            }
            if (element.valorparcial == 1) {
              element.valorparcial = true;
            } else {
              element.valorparcial = false;
            }
            if (element.noaplica == 1) {
              element.noaplica = true;
            } else {
              element.noaplica = false;
            }

            if (element.aplicagantt == 1) {
              element.aplicagantt = true;
            } else {
              element.aplicagantt = false;
            }

          });
          
        this.dataSourceEstandar = data;
        this.dataSourceResultadosECAFormato = data;
        this.dataSourceResultadosNO = data.filter(data => data.valorno == true);
        this.dataSourceResultadosConRecomendacion = data.filter(data => data.recomendacion != '');
        this.obtenerMultas(this.dataSourceResultadosNO);

      });



      this.ordentrabajoresultadoService.getOrdenTrabajoResultadoListadoDocumentacion(myJSON).then(data => {
        data.forEach(
          element => {

            if (element.entregado == 1) {
              element.entregado = true;
            } else {
              element.entregado = false;
            }

            if (element.aplica == 1) {
              element.aplica = true;
            } else {
              element.aplica = false;
            }

          });
        this.dataSourceListados = data;

      });


      this.ordentrabajoresultadoService.getOrdenTrabajoResultadoListadoInformacion(myJSON).then(data => {
        data.forEach(
          element => {

            if (element.entregado == 1) {
              element.entregado = true;
            } else {
              element.entregado = false;
            }
            if (element.aplica == 1) {
              element.aplica = true;
            } else {
              element.aplica = false;
            }


          });
        this.dataSourceEntrega = data;

      });



      this.ordentrabajoetapaService.getOrdenTrabajoEtapaConsulta(myJSON).then(data => {
        this.dataSourceEtapa = data
      }
      );


      this.estatusService.getGrupoEstatusOrden(myJSON).then(data => this.dataSourceEstatus = data);

    },
    obtenerFechaMasAntigua(value) {
      let fechaAntigua = null;
      let fechaFinal = null;
      for (let objeto of value) {
        const fechaObjetoInicio = new Date(objeto.start);
        const fechaObjetoFinal = new Date(objeto.end);

        if (!fechaAntigua || fechaObjetoInicio < fechaAntigua) {
          fechaAntigua = fechaObjetoInicio;
        }

        if (!fechaFinal || fechaObjetoFinal > fechaFinal) {
          fechaFinal = fechaObjetoFinal;
        }
      }

      this.fechaInicio = fechaAntigua;
      this.fechaFinal = fechaFinal;
      this.fechaActual = new Date();

    },
    passwordComparison() {
      return this.customer.Password;
    },
    checkComparison() {
      return true;
    },
    asyncValidation(params) {
      return sendRequest(params.value);
    },
    handleSubmit(e) {




      let myJSON = JSON.stringify({
        pid: this.customer.ID,
        pnombre: this.customer.Nombre,
        ppaterno: this.customer.Paterno,
        pmaterno: this.customer.Materno,
        pusuario: this.customer.Usuario,
        pcontrasena: this.customer.Password,
        pcorreo: this.customer.Email,
      });


      this.usuarioService.updateUsuarioPerfil(myJSON).then(data => {

        if (data.err == 0) {
          Cookies.set("ets_user", "null");
          let myJSON = JSON.stringify({
            id: this.customer.ID,
            nombre: this.customer.Nombre + ' ' + this.customer.Paterno + ' ' + this.customer.Materno,
            correo: this.customer.Email,
          });
          Cookies.set("ets_user", myJSON);
          auth.logIn(this.customer.Nombre + ' ' + this.customer.Paterno + ' ' + this.customer.Materno);
          notify({
            message: 'Perfil ha sido actualizado',
          }, 'success', 9000);
          document.location.reload();
        } else {

          notify({
            message: 'Ocurrio un error ' + data.msg,
            position: {
              my: 'center top',
              at: 'center top',
            },
          }, 'error', 9000);
          document.location.reload();

        }


      });

      e.preventDefault();
    },
    editProcedureListadoInformacion(e) {
      let vresponsabilidadentregaid = e.data.responsabilidadentregaid;
      let ventregado = e.data.entregado == true ? 1 : 0;
      let vaplica = e.data.aplica == true ? 1 : 0;
      let idusuario = JSON.parse(Cookies.get("ets_user")).id;
      let myJSON;

      if (e.data.aplica == true || e.data.aplica == 'true') {
        myJSON = JSON.stringify({
          pid: e.data.id,
          pentregado: null,
          pfechaentrega: '9999-01-01',
          pfechacompromisoentrega: '9999-01-01',
          pusuarioregistroid: idusuario,
          pobservaciones: '',
          precibio: '',
          paplica: vaplica,
          presponsabilidadentregaid: vresponsabilidadentregaid
        });
      } else {
        myJSON = JSON.stringify({
          pid: e.data.id,
          pentregado: ventregado,
          pfechaentrega: (e.data.fechaentrega == '') ? '9999-01-01' : moment(e.data.fechaentrega).format('YYYY-MM-DD'),
          pfechacompromisoentrega: (e.data.fechacompromisoentrega == '') ? '9999-01-01' : moment(e.data.fechacompromisoentrega).format('YYYY-MM-DD'),
          pusuarioregistroid: idusuario,
          pobservaciones: (e.data.observaciones == '') ? '' : e.data.observaciones,
          precibio: (e.data.recibio == '') ? '' : e.data.recibio,
          paplica: vaplica,
          presponsabilidadentregaid: vresponsabilidadentregaid
        });
      }

      this.esCampoRequerido = true;

      this.ordentrabajoresultadoService.updateOrdenTrabajoListadoInformacion(myJSON).then(data => {

        if (data.err == 0) {
          this.type = 'success';
          this.message = 'Se ha actualizado el registro';
          this.isVisible = true;

          notify({
            message: this.message,
          }, this.type, 9000);

        } else {

          this.type = 'error';
          this.message = 'Ocurrio un error ' + data.msg;
          this.isVisible = true;
          notify({
            message: 'Ocurrio un error ' + data.msg,
            position: {
              my: 'center top',
              at: 'center top',
            },
          }, 'error', 9000);
        }
        this.loadDataInfo();

      });

    },
    editProcedureListadoDocumentacion(e) {
      let vresponsabilidadentregaid = e.data.responsabilidadentregaid;
      let ventregado = e.data.entregado == true ? 1 : 0;
      let vaplica = e.data.aplica == true ? 1 : 0;
      let myJSON;
      if (e.data.aplica == true || e.data.aplica == 'true') {
        myJSON = JSON.stringify({
          pid: e.data.id,
          pentregado: null,
          pfechaentrega: null,
          paplica: vaplica,
          presponsabilidadentregaid: vresponsabilidadentregaid
        });
      } else {
        myJSON = JSON.stringify({
          pid: e.data.id,
          pentregado: ventregado,
          pfechaentrega: moment(e.data.fechaentrega).format('YYYY-MM-DD'),
          paplica: vaplica,
          presponsabilidadentregaid: vresponsabilidadentregaid
        });
      }

      this.esCampoRequerido2 = true;

      console.log(myJSON);

      this.ordentrabajoresultadoService.updateOrdenTrabajoListadoDocumentacion(myJSON).then(data => {

        if (data.err == 0) {
          this.type = 'success';
          this.message = 'Se ha actualizado el registro';
          this.isVisible = true;

          notify({
            message: this.message,
          }, this.type, 9000);

        } else {

          this.type = 'error';
          this.message = 'Ocurrio un error ' + data.msg;
          this.isVisible = true;
          notify({
            message: 'Ocurrio un error ' + data.msg,
            position: {
              my: 'center top',
              at: 'center top',
            },
          }, 'error', 9000);
        }
        this.loadDataInfo();

      });

    },
    editProcedureResultado(e) {

      let vsi = e.data.valorsi == true ? 1 : 0;
      let vno = e.data.valorno == true ? 1 : 0;
      let vparcial = e.data.valorparcial == true ? 1 : 0;
      let vnoaplica = e.data.noaplica == true ? 1 : 0;
      let idusuario = JSON.parse(Cookies.get("ets_user")).id;
      let myJSON = JSON.stringify({
        pid: e.data.id,
        pvalorsi: vsi,
        pvalorno: vno,
        pvalorparcial: vparcial,
        pnoaplica: vnoaplica,
        pusuarioregistroid: idusuario,
        pdiashabiles: 0,
        pobservacion: e.data.observacion,
        precomendacion: e.data.recomendacion,

      });



      this.ordentrabajoresultadoService.updateOrdenTrabajoResultado(myJSON).then(data => {

        if (data.err == 0) {
          this.type = 'success';
          this.message = 'Se ha actualizado el registro';
          this.isVisible = true;

          notify({
            message: this.message,
          }, this.type, 9000);
          this.loadDataInfo();
        } else {

          this.type = 'error';
          this.message = 'Ocurrio un error ' + data.msg;
          this.isVisible = true;
          notify({
            message: 'Ocurrio un error ' + data.msg,
            position: {
              my: 'center top',
              at: 'center top',
            },
          }, 'error', 9000);
        }
        this.loadDataInfo();

      });

    },
    editorPreparingListados(e) {
      if (e.dataField === 'aplica' && e.parentType === 'dataRow') {

        e.editorOptions.onValueChanged = (innerEvent) => {
          //this.$forceUpdate();
          // innerEvent.value contiene el nuevo valor del checkbox
          let valor = innerEvent.value;
          this.esCampoRequerido = !valor;
          e.setValue(valor);

        };
      }
    },
    editorPreparingDocumentos(e) {
      if (e.dataField === 'aplica' && e.parentType === 'dataRow') {

        e.editorOptions.onValueChanged = (innerEvent) => {
          //this.$forceUpdate();
          // innerEvent.value contiene el nuevo valor del checkbox
          let valor = innerEvent.value;
          this.esCampoRequerido2 = !valor;
          e.setValue(valor);

        };
      }
    },
    cambioEscala(e) {
      this.tipoEscala = e.value;
    },
    onInitNewRow(e) {
      e.data.entregado = true;
    },
  },
};
